import React, { useState, useEffect } from 'react';

import * as ls from '../../utils/helper/ls-vars';
import * as bintu from '../../utils/bintu/api-requests';

import { makeStyles } from '@mui/styles';
import { Button, Collapse, Grid, TextField, Typography } from '@mui/material';
import { WebhookOutlined } from '@mui/icons-material';

import SectionHeader from '../global/SectionHeader';
import SectionContainer from '../global/SectionContainer';
import SnackbarMessage from '../global/SnackbarMessage';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    button: {
        marginRight: `${theme.spacing(1)} !important`
    }
}));

export default function Webhook(props) {
    const classes = useStyles();
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});
    const [webhook, setWebhook] = useState(false);
    const [update, setUpdate] = useState(false);
    const [updatedWebhok, setUpdatedWebhook] = useState(webhook);

    const handleClickUpdate = () => {
        if (update) setUpdatedWebhook(webhook);
        setUpdate(!update)
    }

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowError(false);
    }

    const handleUpdatedWebhook = (event) => {
        setUpdatedWebhook(event.target.value)
    }

    const handleSubmitWebhook = (newWebhook) => () => {
        let data = {
            webhook: newWebhook?.trim().length === 0 ? null : newWebhook
        }

        bintu.setWebhook(data)
            .then((response) => {
                if (response.success) {
                    setUpdatedWebhook(response.data.webhook);
                    setUpdate(false);
                }
            }).catch((error) => {
                if (!error.success) {
                    setError(error);
                    setShowError(true);
                }
            })
    }

    useEffect(() => {
        setWebhook(props.webhook);
        setUpdatedWebhook(props.webhook);
    }, [])

    return (
        <SectionContainer
            title="Custom web hook"
            interact
            icon={<WebhookOutlined />}
            clicked={handleClickUpdate}
            button={update ? "Cancel" : "Create web hook"}
        >
            <SnackbarMessage
                open={showError}
                close={handleCloseError}
                type={"error"}
            >
                <b>Error: {error.code}</b> {error.message}
            </SnackbarMessage>
            <Grid item xs={12}>
                <Grid container>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        autoComplete="false"
                        value={updatedWebhok ? updatedWebhok : ""}
                        placeholder={updatedWebhok ? "" : 'Click on "Create web hook" and add a webhook.'}
                        variant="standard"
                        disabled={!update}
                        onChange={handleUpdatedWebhook}
                        helperText={update && "Type in your custom webhook and click on save."}
                    />
                </Grid>
                <Grid item xs={12} mt={1}>
                    <Collapse in={update}>
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={handleSubmitWebhook(updatedWebhok)}
                        >
                            Save
                        </Button>
                        <Button
                            size="small"
                            variant="outlined"
                            color="primary"
                            onClick={handleSubmitWebhook(null)}
                        >
                            Reset
                        </Button>
                    </Collapse>
                </Grid>
            </Grid >
        </SectionContainer >

    )
}
