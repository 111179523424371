import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import * as ls from '../../utils/helper/ls-vars';
import * as link from '../../utils/helper/link-config';
import * as bintu from '../../utils/bintu/api-requests';

import { makeStyles } from '@mui/styles';
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, Icon, IconButton, InputAdornment, List, ListItem, ListItemButton, ListItemText, TextField, Typography, alpha, useTheme } from '@mui/material';
import { Close, Search, SearchOffOutlined } from '@mui/icons-material';
import State from '../global/State';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    content: {
        padding: `0 !important`
    },
    container: {
        alignItems: 'flex-start !important',
        marginTop: '20%'
    }
}));

const NoResultFound = (props) => {
    const theme = useTheme();

    return (
        <Box p={2} textAlign="center">
            <SearchOffOutlined sx={{ margin: '0 auto', display: 'block', fontSize: theme.typography.h1.fontSize }} />
            <Typography variant="h6">
                No results found for "<b>{props.search}</b>"
            </Typography>
            <Typography mt={2} variant="body1" color="textSecondary">
                Try searching for: <br />
                - A valid nanoStream Cloud stream ID
                - A valid nanoStream Cloud streamname in the following format: <b>'XXXXX-YYYYY'</b>. <br />
                - The first part of the stream name is your organisation hash. <br />
                - The search is case <b>sensitive</b>. Please note that <i>"AA"</i> is not the same as <i>"aa"</i>.
            </Typography>
        </Box>
    )
}

const Result = (props) => {

    return (
        <Box>
            <List sx={{ p: 0 }}>
                <ListItem disablePadding>
                    <ListItemButton onClick={props.handleRedirect}>
                        <State state={props.stream?.state} />
                        <ListItemText
                            sx={{ ml: 1 }}
                            primary={props.stream?.ingest.rtmp?.streamname}
                            secondary={`${props.stream?.id}`}
                        />
                    </ListItemButton>
                </ListItem>
            </List>
        </Box >
    )
}

export default function SearchField(props) {
    const classes = useStyles();
    const theme = useTheme();
    const navigate = useNavigate();
    const apikey = localStorage.getItem(ls.BINTU_APIKEY);
    const [open, setOpen] = useState(false);
    const [query, setQuery] = useState("");
    const [hint, setHint] = useState(false);
    const [stream, setStream] = useState(null);


    const handleUpdateTextfield = (event) => {
        setQuery(event.target.value)
    }


    const handleRedirect = () => {
        openSearch();
        navigate(`${link.STREAM}/${stream?.id}`);
    }

    const getStream = () => {
        let streamname = query.trim();
        let data = {
            apikey,
            streamid: streamname
        }
        bintu.getStreamById(data)
            .then((response) => {
                if (response.success) {
                    setHint(false);
                    setStream(response.data)
                }
            }).catch((error) => {
                setStream(false);
                setHint(true);
            })
    }

    const openSearch = () => {
        setOpen(!open);
    }

    const handleClose = () => {
        if (query.trim() !== "") {
            setQuery("");
            setStream("");
            setHint(false);
        }
        if (query.trim() === "") openSearch();
    }

    const handleAnyKeyPressed = (event) => {
        let code = event.charCode
        switch (code) {
            case 13: getStream(); break;
            default: break;
        }
    }

    useEffect(() => {
        if (query?.trim().length >= 11) getStream();
        if (query?.trim().length > 5 && query?.trim().length < 11) {
            setStream(null);
            setHint(true);
        }
        if (query.trim() === "") {
            setStream(null);
            setHint(false);
        }
    }, [query])

    useEffect(() => {
        if (!open) {
            setStream(null);
            setQuery("");
        }
    }, [open])

    return (
        <Grid container sx={{ width: 'auto' }}>
            <Dialog
                open={open}
                onClose={openSearch}
                classes={{
                    container: classes.container
                }}
                PaperProps={{
                    sx: { width: "80%", maxWidth: 700 }
                }}
                BackdropProps={{
                    sx: { backdropFilter: "blur(4px)" }
                }}
            >
                <DialogTitle sx={{ p: 0 }}>
                    <TextField
                        fullWidth
                        inputRef={input => query === "" && input && input.focus()}
                        value={query}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search color="primary" />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment>
                                    <IconButton size="small" onClick={handleClose}>
                                        <Close fontSize="inherit" />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            sx: {
                                root: {
                                    borderWidth: `0 !important`,
                                }
                            }
                        }}
                        variant="outlined"
                        placeholder="Type in a streamname or stream ID"
                        onChange={handleUpdateTextfield}
                        onKeyPress={handleAnyKeyPressed}
                    />
                </DialogTitle>
                <DialogContent className={classes.content}>
                    {
                        stream && <Result stream={stream} handleRedirect={handleRedirect} />
                    }
                    {
                        hint && <NoResultFound search={query} />
                    }
                </DialogContent>
            </Dialog>
            <Grid item>
                {
                    props.icon
                    &&
                    <IconButton
                        disableRipple
                        onClick={openSearch}
                    >
                        <Search
                            sx={{
                                color: theme.palette.mode === "light" ? theme.palette.common.white : theme.palette.primary.main
                            }}
                        />
                    </IconButton>
                }
                {
                    props.button
                    &&
                    <Button
                        variant="outlined"
                        startIcon={<Search />}
                        onClick={openSearch}
                        size="small"
                        sx={{
                            color: theme.palette.mode === "light" ? theme.palette.common.white : "primary",
                            borderColor: theme.palette.mode === "light" ? theme.palette.common.white : "primary",
                        }}
                    >
                        Search...
                    </Button>
                }
            </Grid>
        </Grid >
    )
}