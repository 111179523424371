import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import * as link from '../utils/helper/link-config';
import * as ls from '../utils/helper/ls-vars';
import * as bintu from '../utils/bintu/api-requests';

import { makeStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';

import Header from '../components/organisation/Header';
import PrivateKeys from '../components/organisation/PrivateKeys';
import Webhook from '../components/organisation/Webhook';
import Tags from '../components/organisation/Tags';
import Loading from '../components/global/Loading';
import VodSetting from '../components/organisation/VodSetting';
import User from '../components/organisation/User';
import SectionContainer from '../components/global/SectionContainer';
import SnackbarMessage from '../components/global/SnackbarMessage';
import SupportInfo from '../components/organisation/SupportInfo';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {

    },
    item: {
    },
    placeholder: {
        marginBottom: `${theme.spacing(1)} !important`
    }
}));

export default function Organisation(props) {
    const classes = useStyles();
    const navigate = useNavigate();
    const apikey = localStorage.getItem(ls.BINTU_APIKEY);
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(true);
    const [orga, setOrga] = useState(false);

    document.title = "Organisation Overview | nanoStream Cloud Dashboard";

    const fetchOrga = () => {
        bintu.getOrganisation(apikey)
            .then((response) => {
                if (response.success) {
                    localStorage.setItem(ls.BINTU_ORGA, JSON.stringify(response.data));
                    setOrga(response.data);
                    setLoading(false);
                }
            }).catch((error) => {
                setLoading(false);
                if (!error.success) {
                    setError(error);
                    setShowError(true);
                }
            })
    }

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowError(false);
    }


    const clickedTag = (tag) => {
        navigate(`${link.STREAMS}?tag=${tag}`);
    }

    useEffect(() => {
        if (loading) fetchOrga();
    }, [])

    return (
        <Grid container className={classes.root}>
            <SnackbarMessage
                open={showError}
                close={handleCloseError}
                type={"error"}
            >
                <b>Error: {error.code}</b> {error.message}
            </SnackbarMessage>
            {
                loading
                    ? <Loading />
                    :
                    <Fragment>
                        {
                            orga
                            &&
                            <Fragment>
                                <Grid item xs={12} mb={1}>
                                    <Header
                                        name={orga.name}
                                        hash={orga.hash}
                                        created={orga.created_at}
                                        metrics={orga.metrics}
                                        sla={orga.sla}
                                        orga={orga}
                                    />
                                </Grid>
                                <Grid
                                    item xs={12} sm={6} mb={2}
                                    sx={{ pr: { xs: 0, sm: 2 } }}
                                >
                                    <SupportInfo
                                        hash={orga.hash}
                                        metrics={orga.metrics}
                                        sla={orga.sla}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} mb={2}>
                                    <PrivateKeys
                                        api={apikey}
                                        player={orga.playerKey}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12} sm={6} mb={2}
                                    sx={{ pr: { xs: 0, sm: 2 } }}
                                >
                                    <User />
                                </Grid>
                                <Grid item xs={12} sm={6} mb={2}>
                                    <SectionContainer
                                        title="Tag Overview"
                                    // underline="Here you can see all tags from your organization. Click on one of them to find streams that use this tag."
                                    >
                                        <div className={classes.placeholder} />
                                        <Tags clicked={clickedTag} />
                                    </SectionContainer>
                                </Grid>
                                <Grid item xs={12} mb={2}>
                                    <Webhook webhook={orga.webhook} />
                                </Grid>
                                {
                                    orga.vod
                                    &&
                                    <Grid item xs={12} mb={2}>
                                        <VodSetting publicVod={orga.publicVOD} />
                                    </Grid>
                                }

                                <Grid item xs={12}>
                                    <Typography variant="body1" color="textSecondary">
                                        You are streaming with <b>nanoStream Cloud</b> since <i>{moment(orga.created_at).utc().format('MMMM Do, YYYY')}</i>.
                                    </Typography>
                                </Grid>
                            </Fragment>
                        }
                    </Fragment>
            }
        </Grid >

    )
}
