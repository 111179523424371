import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import * as link from '../utils/helper/link-config';
import * as ls from '../utils/helper/ls-vars';
import * as bintu from '../utils/bintu/api-requests';

import { makeStyles } from '@mui/styles';
import { Collapse, Button, Grid, Tooltip } from '@mui/material';
import { AddOutlined, RemoveOutlined } from '@mui/icons-material';

import SectionHeader from '../components/global/SectionHeader';
import AddTags from '../components/create/AddTags';
import AddTranscoding from '../components/create/AddTranscoding';
import AddMetadata from '../components/create/AddMetadata';
import AddLiveProcessing from '../components/create/AddLiveProcessing';
import SnackbarMessage from '../components/global/SnackbarMessage';
import AddThumbnails from '../components/stream/opcodes/AddThumbnails';
import AddThumbnail from '../components/create/AddThumbnail';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
}));

const OptionsButton = (props) => {
    const classes = useStyles();
    const [open, setOpen] = useState(props.option.add);
    const [enabled, setEnabled] = useState(props.option.enabled);

    const handleClickedButton = () => {
        props.clicked(props.title);
    }

    useEffect(() => {
        if (props.option.add !== open) setOpen(props.option.add);
        if (props.option.enabled !== enabled) setEnabled(props.option.enabled)
    }, [props.option])


    return (
        enabled ? (
            <Button
                size="small"
                sx={{ mx: 1 }}
                variant="outlined"
                color="primary"
                onClick={handleClickedButton}
                startIcon={open ? <RemoveOutlined /> : <AddOutlined />}
            >
                {open ? "Remove" : "Add"} {props.title}
            </Button>
        ) : (
            <Tooltip title={`Contact Sales to enable ${props.title}.`}>
                <span>
                    <Button
                        size="small"
                        disabled
                        sx={{ mx: 1 }}
                        variant="outlined"
                        startIcon={<AddOutlined />}
                    >
                        Add {props.title}
                    </Button>
                </span>
            </Tooltip>
        )
    );
}

export default function CreateStream(props) {
    const classes = useStyles();
    const navigate = useNavigate();
    const orga = JSON.parse(localStorage.getItem(ls.BINTU_ORGA));
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});
    const [tags, setTags] = useState([]);
    const [timecode, setTimecode] = useState(0);
    const [transcodes, setTranscodes] = useState([]);
    const [thumbnail, setThumbnail] = useState({ process: true, interval: 30 });
    const [options, setOptions] = useState({
        transcoding: { enabled: orga.transcoding, add: true },
        thumbnail: { enabled: true, add: false },
        timecode: { enabled: orga.metadata, add: false },
    });

    document.title = "Create new Stream | nanoStream Cloud Dashboard";

    const updateTagList = (list) => {
        setTags(list);
    }

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowError(false);
    }

    const updateTranscodingProfiles = (profiles) => {
        setTranscodes(profiles);
    }

    const updateTimecode = (newTimecode) => {
        setTimecode(newTimecode)
    }

    const updateThumbnail = (interval) => {
        setThumbnail(interval)
    }

    const expand = (id) => {
        setOptions({
            ...options,
            [id]: {
                ...options[id],
                add: !(options[id].add)
            }
        })
    }

    const handleCreateNewStream = async () => {
        let hasTranscodes = options.transcoding.enabled && options.transcoding.add
        let hasThumbnail = options.thumbnail.add;
        let hasTimecode = options.timecode.enabled && options.timecode.add;
        let transcodeList = hasTranscodes ? transcodes : [];

        let streamData = {
            ...(tags.length > 0 && { tags }),
            ...(hasTranscodes && { transcodes: transcodeList })
        }

        bintu.createNewStream(streamData)
            .then((response) => {
                if (response.success) {
                    let stream = response.data;

                    if (hasThumbnail) {
                        let thumbnailData = {
                            streamid: stream.id,
                            opcode: thumbnail.process ? "thumbs" : "none",
                            ...thumbnail.process && { interval: parseInt(thumbnail.interval) }
                        }

                        bintu.setOpcode(thumbnailData)
                            .then((response) => {
                            }).catch((error) => {
                                if (!error.success) {
                                    setError(error);
                                    setShowError(true);
                                }
                            })
                    }
                    if (hasTimecode) {
                        let timecodeData = {
                            streamid: stream.id,
                            hasTimecode: options.timecode.add,
                            timecode: timecode
                        };
                        bintu.setTimecode(timecodeData)
                            .then((response) => {
                            }).catch((error) => {
                                if (!error.success) {
                                    setError(error);
                                    setShowError(true);
                                }
                            })
                    }
                    navigate(`${link.NEW_STREAM}/${stream.id}`)
                }
            }).catch(() => {
                if (!error.success) {
                    setError(error);
                    setShowError(true);
                }
            })

    }


    useEffect(() => {
        if (orga) {
            setOptions({
                ...options,
                transcoding: {
                    add: true,
                    enabled: orga.transcoding
                },
                thumbnail: {
                    ...options.thumbnail,
                    enabled: true
                },
                timecode: {
                    ...options.timecode,
                    enabled: orga.metadata
                },
            })
        }
    }, [])



    return (
        <Grid container className={classes.root}>
            <SnackbarMessage
                open={showError}
                close={handleCloseError}
                type={"error"}
            >
                <b>Error: {error.code}</b> {error.message}
            </SnackbarMessage>
            <Grid item xs={12}>
                <SectionHeader
                    title="Create new Stream"
                    underline="Here you can create a new stream."
                />
            </Grid>
            <Grid item xs={12}>
                <AddTags
                    title="Add Tags"
                    underline="Add tag(s) that you can use to identify your stream more easily in the future."
                    updateTagList={updateTagList}
                />
            </Grid>
            <Grid item xs={12} mt={2}>
                {
                    Object.values(options).map((p, i) => (
                        <OptionsButton
                            key={i}
                            title={Object.keys(options)[i]}
                            option={p}
                            clicked={expand}
                        />
                    ))
                }
            </Grid>
            <Grid item xs={12}>
                <Collapse in={options.transcoding.add}>
                    <AddTranscoding applyTranscodingProfiles={updateTranscodingProfiles} />
                </Collapse>
            </Grid>
            <Grid item xs={12} sm={options.timecode.add ? 6 : 12}>
                <Collapse in={options.thumbnail.add}>
                    <AddThumbnail updateThumbnail={updateThumbnail} />
                </Collapse>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Collapse in={options.timecode.add}>
                    <AddMetadata updateTimecode={updateTimecode} />
                </Collapse>
            </Grid>
            <Grid item xs={12} mt={2}>
                <Button
                    variant="contained"
                    onClick={handleCreateNewStream}
                >
                    Create new Stream
                </Button>
            </Grid>
        </Grid>
    )
}
