import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import * as link from '../../utils/helper/link-config';

import { makeStyles } from '@mui/styles';
import { useTheme } from '@emotion/react';
import { Button, Chip, Divider, Grid, Tooltip, Typography } from '@mui/material';
import { CancelOutlined, CheckCircle, CheckCircleOutline, LockOutlined } from '@mui/icons-material';
import { Box } from '@mui/system';

import State from '../global/State';
import CopyButton from '../global/CopyButton';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        width: '100',
        overflow: 'scroll'
    },
    infoWrapper: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: theme.spacing(1),
        flexWrap: 'wrap',
        padding: theme.spacing(2),
    },
    playerInfo: {
        marginRight: theme.spacing(3),
        marginTop: theme.spacing(1),
    },
    streamname: {
        color: theme.palette.mode === 'light' ? theme.palette.common.black : theme.palette.grey[100],
        fontWeight: 900
    },
}));

export default function PlayerUnderline(props) {
    const classes = useStyles();
    const theme = useTheme();
    const navigate = useNavigate();
    let stream = props.stream;
    const [playerState, setPlayerState] = useState(props.playerState);

    const handleClickedFullStreamInfo = () => {
        navigate(`${link.STREAM}/${stream.id}`);
    }

    useEffect(() => {
        setPlayerState(props.playerState);
    }, [props.playerState])


    return (
        <Grid container className={classes.root} alignItems={'center'} flexWrap={'wrap'} spacing={1}>
            <Grid item>
                <State state={stream.state} />
            </Grid>
            <Grid item>
                <Divider orientation="vertical" variant="middle" flexItem style={{ height: theme.spacing(2) }} />
            </Grid>
            <Grid item>
                <Chip size="small" variant="outlined" className={classes.version} label={`PLAYER VERSION ${props.version}`} />
            </Grid>
            <Grid item>
                <Divider orientation="vertical" variant="middle" flexItem style={{ height: theme.spacing(2) }} />
            </Grid>
            <Grid item>
                <Typography variant="body1" color="textSecondary">
                    {moment(stream.created_at).utc().format('MM/DD/YYYY hh:mm a')} (UTC)
                </Typography>
            </Grid>
            {
                props.isTranscode
                &&
                <Fragment>

                    <Grid item>
                        <Divider orientation="vertical" variant="middle" flexItem style={{ height: theme.spacing(2) }} />
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" color={props.isTranscode ? "primary" : "textSecondary"}>
                            <CheckCircle sx={{ fontSize: 12 }} color="primary" /> Streamgroup
                        </Typography>
                        {/* <Divider orientation="vertical" variant="middle" flexItem /> */}
                    </Grid>
                </Fragment>
            }
            {
                props.isSecure
                &&
                <Fragment>
                    <Grid>
                        <Divider orientation="vertical" variant="middle" flexItem style={{ height: theme.spacing(2) }} />
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" color="primary">
                            <LockOutlined sx={{ fontSize: 12 }} color="primary" />  Secure Stream
                        </Typography>
                    </Grid>
                </Fragment>
            }
        </Grid >
    )
}
