import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import nsc from '../../assets/nsc.png';

import { makeStyles, useTheme } from '@mui/styles';
import { alpha, Box, Chip, Grid, Tooltip } from '@mui/material';
import { CameraOutlined, CloudSyncOutlined, GraphicEqOutlined, GridView, MovieOutlined, PlayCircleOutlineRounded, ShieldOutlined } from '@mui/icons-material';
import SectionContainer from '../global/SectionContainer';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    chip: {
        fontWeight: 600,
        '&:hover': {
            transform: 'scale(1.01)'
        }
    },
    enabled: {
        color: `${theme.palette.common.white} !important`,
        border: `1px solid ${theme.palette.primary.light} !important`,
        background: `${theme.palette.primary.light} !important`,
        backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 1)}, ${alpha(theme.palette.primary.main, 0.8)})`,
        "&>svg": {
            color: `${theme.palette.common.white} !important`
        }
    },
    disabled: {
        color: `${theme.palette.grey[400]} !important`,
        border: `1px solid ${theme.palette.grey[400]} !important`,
        backgroundImage: theme.palette.mode === 'light' ? `linear-gradient(135deg, ${alpha(theme.palette.grey[200], 0.9)}, ${alpha(theme.palette.grey[200], 0.7)})` : theme.palette.grey[900],
        "&>svg": {
            color: `${theme.palette.grey[400]} !important`
        },
    },
}));

export default function Options(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [pack, setPack] = useState([]);

    useEffect(() => {
        if (props.orga) {
            let o = props.orga
            setPack([
                { label: "H5Live", value: o.h5live, icon: <PlayCircleOutlineRounded /> },
                { label: "Webcaster", value: o.webrtc, icon: <CameraOutlined /> },
                { label: "Secure", value: o.secure, icon: <ShieldOutlined /> },
                { label: "Transcoding", value: o.transcoding, icon: <GraphicEqOutlined /> },
                { label: "VOD", value: o.vod, icon: <MovieOutlined /> },
                // { label: "nanoMeet", value: o.meetEnabled, icon: <GridView /> },
                { label: "Metadata", value: o.metadata, icon: <CloudSyncOutlined /> },
            ])
        }
    }, [])

    return (
        // <Grid container
        //     sx={{
        //         position: "relative",
        //         border: `${theme.palette.primary.main} ${theme.spacing(0.3)} solid`,
        //         borderRadius: theme.spacing(1.5),
        //         p: theme.spacing(2), mt: theme.spacing(2)
        //     }}>
        //     <Box
        //         component={"img"}
        //         src={nsc}
        //         sx={{
        //             position: "absolute",
        //             top: -20,
        //             left: 10,
        //             width: 'auto',
        //             height: '40px',
        //             background: theme.palette.primary.main,
        //             padding: theme.spacing(1),
        //             borderRadius: theme.spacing(1.5),
        //             backgroundSize: 'cover',
        //         }}
        //     ></Box>
        //     <Grid container spacing={1}>
        //         {
        //             pack
        //                 .sort((a, b) => b.value - a.value)
        //                 .map((p) => (
        //                     <Grid
        //                         key={p.label}
        //                         className={classes.item}
        //                         item
        //                     >
        //                         <Tooltip title={`Contact sales to enable ${p.label}.`} disableHoverListener={p.value}>
        //                             <Chip
        //                                 className={clsx(classes.chip, {
        //                                     [classes.enabled]: p.value,
        //                                     [classes.disabled]: !p.value,
        //                                 })}
        //                                 // color={p.value ? "warning" : "default"}
        //                                 icon={p.icon}
        //                                 label={p.label.toUpperCase()}
        //                             />
        //                         </Tooltip>
        //                     </Grid>
        //                 ))
        //         }
        //     </Grid>
        // </Grid>
        <Grid container mt={1} spacing={1}>
        {
            pack
                .sort((a, b) => b.value - a.value)
                .map((p) => (
                    <Grid
                        key={p.label}
                        className={classes.item}
                        item
                    >
                        <Tooltip title={`Contact sales to enable ${p.label}.`} disableHoverListener={p.value}>
                            <Chip
                                className={clsx(classes.chip, {
                                    [classes.enabled]: p.value,
                                    [classes.disabled]: !p.value,
                                })}
                                // color={p.value ? "warning" : "default"}
                                icon={p.icon}
                                label={p.label.toUpperCase()}
                            />
                        </Tooltip>
                    </Grid>
                ))
        }
        </Grid>
    )
}
