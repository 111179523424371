import React, { useState } from 'react';

import { makeStyles } from '@mui/styles';
import { Divider, Grid, Typography } from '@mui/material';
import ContentTable from '../global/ContentTable';
import SectionContainer from '../global/SectionContainer';
import { HeadsetOutlined } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    supportInfoRoot: {
        // marginTop: theme.spacing(2),
        // padding: theme.spacing(2),
        // backgroundColor: theme.palette.primary.main,
        // opacity: 1,
        // borderRadius: theme.spacing(0.5),
        // backgroundImage: `radial-gradient(circle, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`
    },
}));

export default function SupportInfo(props) {
    const classes = useStyles();
    const [data] = useState([
        { label: "Orga Hash", value: props.hash, copy: true },
        { label: "Player Metrics", value: props.metrics === null ? "No metrics" : `${props.metrics} metrics`, copy: false },
        { label: "Support Level", value: props.sla === null ? "Basic Support" : props.sla, copy: false },
    ]);

    return (
        <SectionContainer title="Organisation">
            <Grid container>
                <Grid item xs={12}>
                    <ContentTable data={data} auto/>
                </Grid>
            </Grid>
        </SectionContainer>
    )
}
