import React, { useState } from 'react';

import * as ls from '../../utils/helper/ls-vars';
import * as bintu from '../../utils/bintu/api-requests';

import { makeStyles } from '@mui/styles';
import { Button, Collapse, Dialog, DialogContent, DialogTitle, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { AddOutlined, OpenInNewOutlined, RemoveOutlined } from '@mui/icons-material';

import SectionHeader from '../global/SectionHeader';
import TokenTarget from '../create/TokenTarget';
import TokenDate from '../create/TokenDate';
import AddSpecificOption from '../create/AddSpecificOption';
import AddTags from '../create/AddTags';
import SnackbarMessage from '../global/SnackbarMessage';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    button: {
        marginRight: `${theme.spacing(1)} !important`,
    }
}));

export default function CreatePlaybackToken(props) {
    const classes = useStyles();
    const apikey = localStorage.getItem(ls.BINTU_APIKEY);

    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});

    const [openMoreOptions, setOpenMoreOptions] = useState(false);

    const [target, setTarget] = useState({
        ...(props.groupid && { group: props.groupid }),
    });

    const [timerange, setTimerange] = useState({
        exp: new Date(Date.now() + 12096e5)
    });
    const [option, setOption] = useState(null);
    const [tag, setTag] = useState(null);
    const [token, setToken] = useState(false);
    const [security, setSecurity] = useState(false);

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowError(false);
    }

    const handleOpenMoreOptions = () => {
        setOpenMoreOptions(!openMoreOptions);
    }

    const handleUpdateTarget = (newTarget) => {
        setTarget(newTarget);
    }

    const handleUpdateDate = (date) => {
        let updatedDate = { ...timerange, ...date };
        setTimerange(updatedDate);
    }

    const handleUpdateSpecificOption = (newOption) => {
        setOption({
            ...option,
            ...newOption
        })
    }

    const handleUpdateTagList = (tagList) => {
        if (tagList.length > 0) {
            setTag({ tag: tagList[0] });
        }
    }

    const handleCreateToken = () => {
        let exp = { exp: Math.floor(timerange.exp.getTime() / 1000) }

        let data = {
            apikey,
            data: {
                ...target,
                ...exp,
                ...(timerange.nbf && { nbf: Math.floor(timerange.nbf.getTime() / 1000) }),
                ...(option && option),
                ...(tag && tag)
            }
        }

        bintu.createH5LiveSecureToken(data)
            .then((response) => {
                if (response.success) {
                    let newToken = response.data.data.token
                    props.handleUpdateToken(newToken)
                }
            }).catch((error) => {
                if (!error.success) {
                    setError(error);
                    setShowError(true);
                }
            })
    }


    return (
        <Grid container className={classes.root}>
            <SnackbarMessage
                open={showError}
                close={handleCloseError}
                type={"error"}
            >
                <b>Error: {error.errorCode}</b> {error.message}
            </SnackbarMessage>
            <Grid item xs={12}>
                <TokenTarget
                    contrast
                    {...props}
                    groupid={props.groupid}
                    updateTarget={handleUpdateTarget}
                />
            </Grid>
            <Grid item xs={12}>
                <TokenDate
                    contrast
                    {...props}
                    title="Set expiration date"
                    id="exp"
                    default={timerange.exp}
                    underline="Set the expiration date of your secure token. The default is two weeks."
                    updateDate={handleUpdateDate}
                />
            </Grid>
            <Grid item xs={12} mt={2}>
                <Button
                    className={classes.button}
                    size="small"
                    variant="outlined"
                    onClick={handleOpenMoreOptions}
                    startIcon={openMoreOptions ? <RemoveOutlined /> : <AddOutlined />}
                >
                    Add more specific options
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Collapse in={openMoreOptions}>
                    <Grid container>
                        <Grid item xs={12} md={6} sx={{ pr: { md: 2 } }}>
                            <TokenDate
                                {...props}
                                contrast
                                title="Set not before date"
                                id="nbf"
                                underline="Set the not before date of your secure token. It can't be before now."
                                updateDate={handleUpdateDate}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <AddSpecificOption
                                {...props}
                                contrast
                                id="ip"
                                placeholder="1.1.1.1"
                                title="Add a client IP (optional)"
                                update={handleUpdateSpecificOption}
                                underline="You can restrict the access/playback of a stream to a specific IP."
                            />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ pr: { md: 2 } }}>
                            <AddSpecificOption
                                {...props}
                                contrast
                                id="domain"
                                placeholder="your-domain.net"
                                title="Add a domain (optional)"
                                update={handleUpdateSpecificOption}
                                underline="You can restrict the access/playback of a stream to a specific domain."
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <AddSpecificOption
                                {...props}
                                contrast
                                id="userid"
                                placeholder="7365zdue7"
                                title="Add a user id (optional)"
                                update={handleUpdateSpecificOption}
                                underline="Add a user id to your token."
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AddTags
                                {...props}
                                contrast
                                title="Add a single tag (optional)"
                                underline="Add a tag to track your token."
                                updateTagList={handleUpdateTagList}
                            />
                        </Grid>
                    </Grid>
                </Collapse>
                <Grid item xs={12} mt={2}>
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={handleCreateToken}
                    >
                        Create new token
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}
