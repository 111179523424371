import React, { Fragment, useState } from 'react';

import { makeStyles } from '@mui/styles';
import { Grid, IconButton, InputAdornment, TextField, Tooltip, Typography } from '@mui/material';
import { InfoOutlined, Visibility, VisibilityOff } from '@mui/icons-material';

import CopyButton from '../global/CopyButton';
import SectionContainer from '../global/SectionContainer';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    }
}));

export default function PrivateKeys(props) {
    const classes = useStyles();
    const [show, setShow] = useState({
        api: false,
        player: false
    });

    const handleClickShowPassword = (id) => () => {
        setShow({
            ...show,
            [id]: !show[id]
        });
    }

    return (
        <SectionContainer >
            <Grid container direction="row">
                <Fragment >
                    <Grid item xs={12} mb={0.5} className={classes.header}>
                        <Typography mr={0.5} sx={{ display: 'inline-block' }} variant="h4" color="primary">
                            API Access
                        </Typography>
                        <Tooltip title={`For security reasons we recommend that you keep your API access confidental.`}>
                            <IconButton size="small">
                                <InfoOutlined fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    {
                        Object.values(props).map((key, i) => (
                            <Grid item key={i} xs={12} mb={2}>
                                <TextField
                                    fullWidth
                                    variant="standard"
                                    type={show[key] ? 'text' : 'password'}
                                    value={key}
                                    label={`${Object.keys(props)[i].charAt(0).toUpperCase() + Object.keys(props)[i].slice(1)} key`}
                                    autoComplete="off"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <CopyButton iconButton copy={key} />
                                                <IconButton
                                                    size="small"
                                                    onClick={handleClickShowPassword(key)}
                                                >
                                                    {show[key] ? <Visibility fontSize="inherit" /> : <VisibilityOff fontSize="inherit" />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                        ))
                    }
                </Fragment>
            </Grid>
        </SectionContainer>
    )
}
