import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useRouteLoaderData } from 'react-router-dom';
import moment from 'moment';

import * as ls from '../../../utils/helper/ls-vars';
import * as link from '../../../utils/helper/link-config';
import * as m from '../../../utils/helper/methods';
import * as bintu from '../../../utils/bintu/api-requests';

import { makeStyles, useTheme } from '@mui/styles';
import { Box, Button, Collapse, Divider, Grid, Link, Tab, Tabs, TextField, Typography } from '@mui/material';
import { CameraOutlined, DeleteOutline, Edit, EditOutlined, List, LockOutlined, OpenInNew, PlayCircleOutline, PlaylistAddOutlined } from '@mui/icons-material';

import placeholder_thumbnail from '../../../assets/no-thumbnail.png';

import StreamUnderline from './StreamUnderline';
import SectionContainer from '../../global/SectionContainer';
import ContentTable from '../../global/ContentTable';
import IFrameCodeSnippet from '../../player/IFrameCodeSnippet';
import CodeSnippet from '../../player/CodeSnippet';
import MiniPlaybackToken from '../../create/MiniPlaybackToken';
import ContentDialog from '../../global/ContentDialog';
import UpdateTags from '../UpdateTags';
import UpdatePushUrl from '../transcoding/UpdatePushUrl';
import UpdateTimecode from '../UpdateTimecode';
import DeleteStream from '../DeleteStream';
import UpdateLiveProcessing from '../opcodes/UpdateLiveProcessing';
import SnackbarMessage from '../../global/SnackbarMessage';
import StopStream from '../StopStream';
import UpdateProfile from '../transcoding/UpdateProfile';
import DeleteProfile from '../transcoding/DeleteProfile';
import AddProfile from '../transcoding/AddProfiles';
import AddThumbnails from '../opcodes/AddThumbnails';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
}));

export default function StreamOverview(props) {
    const classes = useStyles();
    const navigate = useNavigate();
    const [openDialog, setOpenDialog] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openStop, setOpenStop] = useState(false);
    const [dialogContent, setDialogContent] = useState(false);
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});

    const { orga, secure } = props;
    const [stream, setStream] = useState(props.stream);
    const [token, setToken] = useState(props.token);
    const [isABR, setIsABR] = useState(stream.playout?.rtmp.length > 1);
    const [noThumbnail, setNoThumbnail] = useState(false);
    const isSecure = props.secure ? (token ? secure : false) : false;
    const hasThumbnail = stream.opcode?.includes("thumbs");
    let securitySuffix = isSecure ? `?security.jwtoken=${token}` : "";
    let embedUrlSuffix = stream.playout.rtmp.length > 1
        ? `group.id=${stream.id}${isSecure ? `&group.security.jwtoken=${token}` : ""}`
        : stream.playout.rtmp.map((s, i) => `entry${i > 0 ? i + 1 : ""}.rtmp.streamname=${s.streamname}${isSecure ? `&entry${i > 0 ? i + 1 : ""}.security.jwtoken=${token}` : ""}${s.info ? `&entry${i > 0 ? i + 1 : ""}.info.bitrate=${s.info.bitrate}` : ""}`).join('&')

    const streamDetails = [
        { label: "Streamgroup Id", value: stream.id, copy: true },
        { label: "Updated at", date: moment(stream.updated_at).utc().format('MM/DD/YYYY hh:mm a (UTC)'), copy: false },
        ...(!isABR ? [{
            label: "Tags",
            tags: stream.tags,
            edit: stream.id
        }] : []),
        ...(!isABR ? [{
            label: "Push Url",
            ...(stream.pushUrl === null && { bool: { is: false } }),
            ...(!(stream.pushUrl === null) && { value: stream.pushUrl }),
            copy: stream.pushUrl === null ? false : true,
            edit: stream.id,
        }] : []),

        ...(props.orga.metadata ? [{ label: "Timecode", bool: { is: stream.timecode }, copy: false, edit: stream.id }] : []),
        ...(props.orga.metadata && stream.timecode ? [{ label: "Timecode Interval", value: stream.timecode_interval, copy: false }] : []),
        // ...(props.orga.vod ? [{
        //     label: "Live Processing",
        //     ...(stream.opcode === null && { bool: { is: false } }),
        //     value: stream.opcode,
        //     copy: stream.opcode === null ? false : true,
        //     edit: stream.id
        // }] : []),
        {
            label: "Thumbnail",
            ...(!(stream.opcode?.includes("thumbs")) && { bool: { is: false } }),
            ...(stream.opcode?.includes("thumbs") && { link: stream.playout.thumbnails?.[0].url }), // `${link.THUMBNAILS}/${stream.ingest?.rtmp.streamname}.jpg`
            copy: stream.opcode?.includes("thumbs") ? stream.playout.thumbnails?.[0].url : false,
            ...(!(stream.opcode?.includes("thumbs")) && { edit: stream.id }),

        },
        ...(hasThumbnail ? [{
            label: "Thumbnail Interval",
            value: `${stream.opcode?.includes(":") ? stream.opcode.split(":")[1] : 30}s`,
            edit: stream.id
        }] : [])
    ];

    const [playoutTab, setPlayoutTab] = useState(0);
    const [playoutDetails, setPlayoutDetails] = useState(stream?.playout?.rtmp);
    const [playoutURL, setPlayoutURL] = useState([
        { label: "Live Playout Url", link: `${link.BASE}${link.PLAYOUT}/${stream.id}${isSecure ? `${securitySuffix}` : ""}`, copy: true },
        { label: "iFrame Embed Url", link: `${link.H5LIVE_EMBED_PLAYER}${embedUrlSuffix}`, copy: true }
    ]);
    const rtmpPlayout = [
        { label: "Streamname", value: stream.playout.rtmp[0]?.streamname, copy: true },
        { label: "Url", value: stream.playout.rtmp[0]?.url, copy: true }
    ];
    const [decodedToken, setDecodedToken] = useState([]);


    const ingest = [
        { label: "RTMP Ingest Streamname", value: stream.ingest?.rtmp.streamname, copy: true },
        { label: "RTMP Ingest Url", value: stream.ingest?.rtmp.url, copy: true }
    ];

    const handleRedirect = (link) => () => {
        navigate(link);
    }

    const openInNewTab = (link) => () => {
        window.open(link, '_blank')
    }

    const handleDeleteStream = () => {
        setOpenDelete(!openDelete)
    }

    const handleStopStream = () => {
        setOpenStop(!openStop)
    }

    const handleNewSecurityOptions = (newToken) => {
        let newDecodedToken = m.DECODE_TOKEN(newToken);
        if (newDecodedToken) {
            setDecodedToken([
                { label: "JWT token", value: newToken, copy: true },
                { label: "Not Before (nbf)", nbf: moment.unix(newDecodedToken.nbf).utc().format('MM/DD/YYYY hh:mm a (UTC)'), copy: false },
                { label: "Expires (exp)", exp: moment.unix(newDecodedToken.exp).utc().format('MM/DD/YYYY hh:mm a (UTC)'), copy: false },
                ...(newDecodedToken.orgahash ? [{ label: "Entire Organisation", bool: { is: true }, copy: false }] : []),
                ...(newDecodedToken.streams ? [{ label: "Stream name(s)", value: `[${newDecodedToken.streams.map(s => s)?.join(", ")}]`, copy: true }] : []),
                ...(newDecodedToken.tag ? [{ label: "Tag", tag: newDecodedToken.tag }] : []),
                ...(newDecodedToken.ip ? [{ label: "IP", value: newDecodedToken.ip, copy: true }] : []),
                ...(newDecodedToken.domain ? [{ label: "Domain", link: newDecodedToken.domain, copy: true }] : []),
            ]);
        }

        if (token !== newToken) {
            securitySuffix = isSecure ? `?security.jwtoken=${newToken}` : "";
            embedUrlSuffix = isSecure
                ? stream.playout.rtmp.length > 1
                    ? `group.id=${stream.id}${isSecure ? `&group.security.jwtoken=${newToken}` : ""}`
                    : stream.playout.rtmp.map((s, i) => `entry${i > 0 ? i + 1 : ""}.rtmp.streamname = ${s.streamname}${isSecure ? `&entry${i > 0 ? i + 1 : ""}.security.jwtoken=${newToken}` : ""}${s.info ? `&entry${i > 0 ? i + 1 : ""}.info.bitrate=${s.info.bitrate}` : ""}`).join('&')
                : embedUrlSuffix;
            let newPlayoutList = playoutURL;
            newPlayoutList[0] = { label: "Live Playout Url", link: `${link.BASE}${link.PLAYOUT}/${stream.id}${isSecure ? `${securitySuffix}` : ""}`, copy: true }
            newPlayoutList[1] = { label: "iFrame Embed Url", link: `${link.H5LIVE_EMBED_PLAYER}${embedUrlSuffix}`, copy: true };
            setPlayoutURL(newPlayoutList);
        }

        setToken(newToken);
        closeDialog();
    }

    const closeDialog = () => {
        setOpenDialog(false);
        setDialogContent(null);
    }

    const updateTranscodes = (label, streamid) => (...values) => {
        let index = playoutDetails.findIndex(p => p.id === streamid);
        let clonedPlayoutDetails = playoutDetails;
        clonedPlayoutDetails[index] = { ...clonedPlayoutDetails[index], [label]: values[0] };
        setPlayoutDetails(clonedPlayoutDetails);
        setStream({
            ...stream,
            playout: {
                ...stream.playout, rtmp: clonedPlayoutDetails
            }
        });
        closeDialog();
    }


    const updated = (...labels) => (...values) => {
        setStream({
            ...stream,
            [labels[0]]: values[0],
            ...(labels[1] && { [labels[1]]: values[1] })
        });
        closeDialog();
    }

    const handleRemoveVOD = (vod, url) => () => {
        let data = {
            streamid: stream.id,
            streamname: vod
        };

        bintu.deleteVOD(data)
            .then((response) => {
                if (response.success) {
                    setOpenDialog(false);
                    let updatedWeb = stream.playout.web.filter(webItem => webItem.url !== url);
                    setStream({
                        ...stream,
                        playout: {
                            ...stream.playout,
                            web: updatedWeb
                        }
                    });
                }
            }).catch((error) => {
                if (!error.success) {
                    setOpenDialog(false);
                    setError(error);
                    setShowError(true);
                }
            })
    }

    const handleClickedRemove = (label, value) => () => {
        switch (label) {
            case "VOD Playout": {
                let removableVod = value?.split("/").pop()?.split(".mp4")?.shift();
                setOpenDialog(true);
                setDialogContent({
                    title: `Remove the VOD with streamname: ${removableVod}`,
                    content:
                        <Grid container mb={1}>
                            <Grid item>
                                <Typography variant="body2">
                                    Please confirm that you want to delete this VOD with the streamname "{removableVod}".
                                </Typography>
                            </Grid>
                            <Grid item xs={12} mt={2}>
                                <Button
                                    size="small"
                                    sx={{ mr: 1, mt: 0.5 }}
                                    variant="contained"
                                    onClick={handleRemoveVOD(removableVod, value)}
                                >
                                    Remove
                                </Button>
                                <Button
                                    size="small"
                                    sx={{ mr: 1, mt: 0.5 }}
                                    variant="outlined"
                                    onClick={props.cancel}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                })
            } break;
            default: break;
        }
    }

    const handleUpdateThumbnail = ({ newOpcode, newThumbnail }) => {
        setStream({
            ...stream,
            opcode: newOpcode,
            playout: {
                ...stream.playout,
                thumbnails: newThumbnail
            }
        })
        closeDialog();
    }

    const handleEditClicked = (label, streamid) => () => {
        let passthrough = streamid === stream.id;
        switch (label) {
            case "Timecode":
                setOpenDialog(true);
                setDialogContent({
                    title: `Update the timecode.`,
                    underline: "You can set the time injection interval between 500 ms and 1 hour. Recommended values are 1000-5000 ms. Set to 0 to disable time injection.",
                    content:
                        <UpdateTimecode
                            updated={updated("timecode_interval", "timecode")}
                            streamid={streamid}
                            timecode={stream.timecode_interval}
                            cancel={closeDialog}
                        />
                })
                break;
            case "Thumbnail Interval":
            case "Thumbnail":
                setOpenDialog(true);
                setDialogContent({
                    title: `Generate thumbnail for stream with id ${streamid}.`,
                    underline: "Set the interval for thumbnail generation. The range is between 10s and 3600s.",
                    content:
                        <AddThumbnails
                            updated={handleUpdateThumbnail}
                            streamid={streamid}
                            streamname={stream.ingest?.rtmp.streamname}
                            opcode={stream.opcode}
                            cancel={closeDialog}
                        />
                })
                break;
            case "Live Processing":
                setOpenDialog(true);
                setDialogContent({
                    title: `Update the live processing for stream with id: ${streamid}.`,
                    content:
                        <UpdateLiveProcessing
                            updated={updated("opcode")}
                            streamid={streamid}
                            opcode={stream.opcode}
                            cancel={closeDialog}

                        />
                })
                break;
            case "Push Url":
                let pushUrl = passthrough ? stream.pushUrl : playoutDetails.filter((p, i) => streamid === p.id)?.map(f => f.pushUrl)[0];
                setOpenDialog(true);
                setDialogContent({
                    title: `Update the pushurl for stream with id: ${streamid}`,
                    content:
                        <UpdatePushUrl
                            updated={passthrough ? updated("pushUrl") : updateTranscodes("pushUrl", streamid)}
                            streamid={streamid}
                            pushUrl={pushUrl}
                            cancel={closeDialog}
                        />
                })
                break;
            case "Tags":
                let tags = passthrough ? stream.tags : playoutDetails.filter((p, i) => streamid === p.id)?.map(f => f.tags)[0];
                setOpenDialog(true);
                setDialogContent({
                    title: `Update tags for stream with id: ${streamid}`,
                    underline: "Manage the tags of your stream. You can set, update, or delete them.",
                    content:
                        <UpdateTags
                            updated={passthrough ? updated("tags") : updateTranscodes("tags", streamid)}
                            streamid={streamid}
                            tags={tags}
                            cancel={closeDialog}
                        />
                })
                break;
            case "Token":
                setOpenDialog(true);
                setDialogContent({
                    title: `Create new playback token.`,
                    underline: "Generate a new token to secure nanoStream H5Live playbacks.",
                    content:
                        <MiniPlaybackToken
                            small
                            groupid={streamid}
                            handleUpdateToken={handleNewSecurityOptions}
                        />
                });
                break;
            case "Delete":
                setOpenDialog(true);
                setDialogContent({
                    title: "Remove this stream",
                    underline: "Deleting the stream will delete all related resources.",
                    content:
                        <Button
                            color="error"
                            variant="contained"
                            sx={{ mt: 1 }}
                            size="small"
                            onClick={handleDeleteStream}
                        >
                            Delete this stream
                        </Button>
                });
                break;
            case "Lock":
                setOpenDialog(true);
                setDialogContent({
                    title: `${stream.state === "locked" ? "Unlock this stream" : "Stop this stream"}`,
                    underline: `${stream.state === "locked" ? "By unlocking this stream you can ingest again." : "By stopping this stream you will not be able to ingest as long as the state is locked."}`,
                    content:
                        <Button
                            color="error"
                            variant="outlined"
                            size="small"
                            sx={{ mt: 1 }}
                            onClick={handleStopStream}
                        >
                            {stream.state === "locked" ? "Unlock" : "Stop"}
                        </Button>
                });
                break;
            default: return;
        }
    }

    const handleEditQuickly = (label) => {
        switch (label) {
            case "Delete": setOpenDelete(true); break;
            case "Lock": setOpenStop(true); break;
            default: return;
        }
    }

    const handleEditProfile = (type, details) => () => {
        switch (type) {
            case "add":
                setOpenDialog(true);
                setDialogContent({
                    title: `Add Transcoding Profile`,
                    underline: `Enhance your stream group with up to 3 transcoding profiles.`,
                    content:
                        <AddProfile
                            small
                            streamid={stream.id}
                            cancel={closeDialog}
                            handleUpdateTranscode={(rtmpObj) => {
                                if (stream?.playout?.rtmp) {
                                    setIsABR(true);
                                    setPlayoutDetails(rtmpObj);
                                }
                                setPlayoutTab(0);
                                closeDialog();
                            }}
                        />
                });
                return;
            case "edit":
                setOpenDialog(true);
                setDialogContent({
                    title: `Modify Transcoding Profile`,
                    underline: "Refine the attributes of your existing transcoding profile.",
                    content:
                        <UpdateProfile
                            small
                            details={details}
                            cancel={closeDialog}
                            handleUpdateTranscode={(rtmpObj) => {
                                if (stream?.playout?.rtmp[rtmpObj.index]) {
                                    const updatedRtmp = [...stream.playout.rtmp];
                                    updatedRtmp[rtmpObj.index] = rtmpObj;
                                    setPlayoutDetails(updatedRtmp);
                                }
                                closeDialog();
                            }}
                        />
                });
                return;
            case "delete":
                setOpenDialog(true);
                setDialogContent({
                    title: `Delete Transcoding Profile`,
                    underline: "Confirm the removal of this transcoding profile from your stream group.",
                    content:
                        <DeleteProfile
                            small
                            details={details}
                            groupid={stream.id}
                            cancel={closeDialog}
                            handleUpdateTranscode={(newRtmp) => {
                                if (newRtmp.length === 1) setIsABR(false);
                                setPlayoutDetails(newRtmp);
                                setPlayoutTab(0);
                                closeDialog();
                            }}
                        />
                });
                return;
            default: return;
        }
    }

    const handleThumbnailError = (err) => {
        setNoThumbnail(true);
    }


    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowError(false);
    }

    const handleChangePlayoutTab = (e, newPlayoutTab) => {
        setPlayoutTab(newPlayoutTab);
    }


    useEffect(() => {
        if (hasThumbnail) {
            setInterval(() => {

            },)
        }
    }, [hasThumbnail])

    useEffect(() => {
        if (isSecure && token) handleNewSecurityOptions(token);
    }, [])

    return (
        <Grid container className={classes.root}>
            <SnackbarMessage
                open={showError}
                close={handleCloseError}
                type={"error"}
            >
                <b>Error: {error.code}</b> {error.message}
            </SnackbarMessage>
            <DeleteStream
                open={openDelete}
                streamid={stream.id}
                cancel={handleDeleteStream}
                deleted={handleRedirect(`${link.STREAMS}`)}
            />
            <StopStream
                open={openStop}
                streamid={stream.id}
                state={stream.state}
                cancel={handleStopStream}
                action={handleRedirect(0)}
            />
            {
                openDialog
                &&
                <ContentDialog
                    open={openDialog}
                    close={closeDialog}
                    hideActions
                    title={dialogContent?.title}
                    underline={dialogContent?.underline}
                    content={dialogContent?.content}
                />
            }
            {
                !props.hideStreamUnderline
                && <Grid item xs={12} mb={1.5}>
                    <StreamUnderline
                        stream={stream}
                        isTranscode={isABR}
                        isSecure={props.secure}
                        edit={handleEditQuickly}
                    />
                </Grid>
            }
            <Grid container spacing={2}>
                <Grid
                    item xs={12}
                    md={(hasThumbnail && !isABR) ? 6 : 12}
                // sx={{ pr: { md: (isABR && playoutDetails) ? 2 : 0 } }}
                >
                    <SectionContainer
                        interact
                        noMargin
                        icon={props.showOverviewButton ? <List /> : <PlayCircleOutline />}
                        title={isABR ? "Streamgroup Details" : "Stream Details"}
                        button={props.showOverviewButton ? "Stream overview" : (stream.state === "live" ? "Watch Stream" : false)}
                        clicked={handleRedirect(`${props.showOverviewButton ? link.STREAM : link.PLAYOUT}/${stream.id}${securitySuffix}`)}
                    >
                        <ContentTable
                            data={streamDetails}
                            edit={handleEditClicked}
                        />
                        {
                            (!isABR && playoutDetails)
                            &&
                            <Fragment>
                                <Typography sx={{ fontStyle: 'italic' }} variant="body2" color="textSecondary" mt={1}>
                                    This stream has no transcoding profiles.
                                    Without enabling adaptive bitrate, the stream is broadcast in its original quality, which can lead to buffering issues for viewers with slower internet connections.
                                </Typography>
                                <Button
                                    sx={{ mt: 1, mr: 1 }}
                                    size="small"
                                    variant="contained"
                                    onClick={handleEditProfile("add")}
                                    endIcon={<PlaylistAddOutlined />}

                                >
                                    Add profile
                                </Button>
                                <Button
                                    sx={{ mt: 1 }}
                                    size="small"
                                    variant="outlined"
                                    onClick={openInNewTab(link.DASHBOARD_ABR_TRANSCODE)}
                                    endIcon={<OpenInNew />}

                                >
                                    Explore ABR
                                </Button>
                            </Fragment>
                        }
                    </SectionContainer>
                </Grid>
                {
                    (isABR && playoutDetails)
                    &&
                    <Grid item xs={12} md={hasThumbnail ? 6 : 12}>
                        <SectionContainer
                            noMargin
                            title="Adaptive Bitrate (ABR) Stream overview"
                            underline="Toggle between the tabs for a detailed view of each stream within the stream group."
                            interact={playoutDetails.length < 4 ? true : false}
                            button={"Add Profile"}
                            icon={<PlaylistAddOutlined />}
                            clicked={handleEditProfile("add")}
                        >
                            <Tabs
                                value={playoutTab}
                                onChange={handleChangePlayoutTab}
                            >
                                {
                                    playoutDetails.map((t, i) => (
                                        <Tab
                                            key={i}
                                            label={i === 0 ? "Passthrough" : `${i}. Transcode`}
                                        />
                                    ))
                                }
                            </Tabs>
                            {
                                playoutDetails.map((playoutDetail, i) =>
                                    <div hidden={playoutTab !== i}>
                                        {
                                            playoutTab === i
                                            &&
                                            <SectionContainer
                                                contrast
                                                small
                                                title={`Stream Details: ${i === 0 ? "Passthrough" : `${i}. Transcode`}`}
                                                underline={`Quality of the adaptive bitrate (ABR) profile.`}
                                            >
                                                <ContentTable
                                                    data={[
                                                        { label: "Stream id", value: playoutDetail.id, copy: true },
                                                        { label: "Streamname", value: playoutDetail.streamname, copy: true },
                                                        ...(i === 0 ? [{
                                                            label: "Push Url",
                                                            ...(stream.pushUrl === null && { bool: { is: false } }),
                                                            ...(!(stream.pushUrl === null) && { value: stream.pushUrl }),
                                                            copy: stream.pushUrl === null ? false : true,
                                                            edit: stream.id
                                                        }] : [
                                                            {
                                                                label: "Push Url",
                                                                ...(playoutDetails[i].pushUrl === null && { bool: { is: false } }),
                                                                ...(!(playoutDetails[i].pushUrl === null) && { value: playoutDetails[i].pushUrl }),
                                                                copy: playoutDetails[i].pushUrl === null ? false : true,
                                                                edit: playoutDetails[i].id
                                                            }
                                                        ]),
                                                        {
                                                            label: "Tags",
                                                            tags: i === 0
                                                                ? stream.tags
                                                                : playoutDetails[i]?.tags, copy: false, edit: playoutDetails[i].id
                                                        },
                                                        ...(playoutDetails[i].info ? [{
                                                            label: "Resolution",
                                                            value: `${playoutDetails[i].info.width === 0
                                                                ? "as input"
                                                                : `${playoutDetails[i].info.width
                                                                    ? `${playoutDetails[i].info.width}x${playoutDetails[i].info.height}`
                                                                    : `n/a`
                                                                }`
                                                                }`,
                                                        }] : []),
                                                        ...(playoutDetails[i].info ? [{ label: "Bitrate", value: `${playoutDetails[i].info.bitrate} kbit/s` }] : []),
                                                        ...(playoutDetails[i].info ? [{
                                                            label: "Framerate",
                                                            value: `${playoutDetails[i].info.fps === 0
                                                                ? "as input"
                                                                : `${playoutDetails[i].info.fps
                                                                    ? `${playoutDetails[i].info.fps} fps`
                                                                    : `n/a`
                                                                }`
                                                                }`
                                                        }] : []),
                                                    ]}
                                                    edit={handleEditClicked}
                                                />
                                                {
                                                    i > 0 &&
                                                    <Box mt={1.5}>
                                                        <Button
                                                            sx={{ mr: .5, mt: .5 }}
                                                            size="small"
                                                            variant="contained"
                                                            endIcon={<EditOutlined />}
                                                            onClick={handleEditProfile("edit", playoutDetails[i])}
                                                        >
                                                            Edit Profile
                                                        </Button>
                                                        <Button
                                                            sx={{ mr: .5, mt: .5 }}
                                                            size="small"
                                                            color="error"
                                                            variant="outlined"
                                                            endIcon={<DeleteOutline />}
                                                            onClick={handleEditProfile("delete", playoutDetails[i])}
                                                        >
                                                            Remove Profile
                                                        </Button>
                                                    </Box>
                                                }
                                            </SectionContainer>
                                        }
                                    </div>
                                )
                            }
                        </SectionContainer>
                    </Grid>
                }
                {
                    hasThumbnail
                    &&
                    <Grid item xs={12} md={6}>
                        <SectionContainer
                            noMargin
                            title="Thumbnail Preview"
                            underline={`The thumbnail is captured every ${stream.opcode?.includes(":") ? stream.opcode.split(":")[1] : 30}s based on the ongoing stream. Refresh the page to update the preview.`}
                            interact
                            clicked={handleEditClicked("Thumbnail", stream.id)}
                            button="Update settings"
                            icon={<Edit />}
                        >
                            <Box
                                component="img"
                                sx={{
                                    height: '100%',
                                    width: '100%',
                                    mt: 1,
                                }}
                                alt="Thumbnail"
                                src={`${noThumbnail ? placeholder_thumbnail : stream.playout.thumbnails?.[0].url}`}
                                onError={handleThumbnailError}
                            />
                        </SectionContainer>
                    </Grid>
                }
            </Grid>
            <Grid item xs={12}>
                <SectionContainer
                    title="Ingest"
                    underline="Copy the info below to your Live Encoder software or hardware (e.g.OBS), or use the nanoStream Webcaster."
                    button="Open nanoStream Webcaster"
                    interact
                    icon={<CameraOutlined />}
                    clicked={handleRedirect(`${link.WEBCASTER}/${stream.id}`)}

                >
                    <ContentTable data={ingest} />
                </SectionContainer>
            </Grid>
            {/* {
                orga.vod
                &&
                <Grid item xs={12}>
                    <OpcodeOverview
                        stream={stream}
                    />
                </Grid>
            } */}
            {
                isSecure && decodedToken
                &&
                <Grid item xs={12}>
                    <SectionContainer
                        title="Playback Token"
                        underline="This is the playback token that you apply once you click on the web playout link."
                        button="Create new Playback Token"
                        interact
                        icon={<LockOutlined />}
                        clicked={handleEditClicked("Token", stream.id)}
                    >
                        <ContentTable data={decodedToken} />
                    </SectionContainer>
                </Grid>
            }
            <Grid item xs={12}>
                <SectionContainer
                    interact
                    icon={<OpenInNew />}
                    title="H5Live Playout"
                    button="nanoPlayer Documentation"
                    clicked={openInNewTab(link.H5LIVE_DOCS)}
                    underline={
                        isSecure
                            ? 'This live playout url contains per default a standard token that is valid for your entire organization. It is valid for 24 hours. If you want to change the security setup for this stream, create a new token by clicking the "Create new Playback Token" button below.'
                            : 'Use this live playout url to access the playback of the stream.'
                    }
                >
                    <ContentTable data={playoutURL} />
                </SectionContainer>
            </Grid>
            <Grid item xs={12} mt={2}>
                <IFrameCodeSnippet stream={stream} security={token} />
                <CodeSnippet stream={stream} security={token} />
            </Grid>
            {
                !isSecure &&
                <Grid item xs={12}>
                    <SectionContainer title="RTMP Playout">
                        <ContentTable data={rtmpPlayout} />
                    </SectionContainer>
                </Grid>
            }
            {
                orga.vod
                &&
                <Grid item xs={12}>
                    <SectionContainer title="VOD Playout URLs">
                        <ContentTable
                            auto
                            remove={handleClickedRemove}
                            data={stream.playout.web
                                .filter((web, i) => web.type === "vod")
                                .map((playoutUrl, i) => ({
                                    label: "VOD Playout",
                                    custom: (
                                        <Typography variant="body2">
                                            <Link underline="hover" href={playoutUrl.url} target="_blank">
                                                {playoutUrl.url}
                                            </Link>
                                            {playoutUrl.size ? ` (${(playoutUrl.size / (1024 * 1024)).toFixed(2)} MB)` : ""}
                                        </Typography>
                                    ),
                                    copy: true,
                                    copyContent: playoutUrl.url
                                    // remove: playoutUrl.url,
                                }))}
                        />
                    </SectionContainer>
                </Grid>
            }
            {
                orga.vod && stream.playout.hls
                &&
                <Grid item xs={12}>
                    <Collapse in={isSecure}>
                        <SectionContainer
                            title="HLS Playout"
                            underline="To see the H5Live Playout see the H5Live section above."
                        >
                            <ContentTable
                                auto
                                data={[{ label: "Live (HLS Legacy / Standard Latency)", link: stream.playout.hls[0]?.url, copy: true }]}
                            />
                        </SectionContainer>
                    </Collapse>
                </Grid>
            }
            {
                !props.hideLockAndStop
                &&
                <Fragment>
                    <Grid
                        item
                        xs={12}
                    >
                        <SectionContainer
                            caution
                            contrastBorder
                            title={stream.state === "locked" ? "Unlock this stream" : "Stop this stream"}
                            underline={stream.state === "locked" ? "By unlocking this stream you can ingest again." : "By stopping this stream you will not be able to ingest as long as the state is locked."}
                        >
                            <Button
                                color="error"
                                variant="outlined"
                                size="small"
                                sx={{ mt: 1 }}
                                onClick={handleStopStream}
                            >
                                {stream.state === "locked" ? "Unlock" : "Stop"}
                            </Button>
                        </SectionContainer>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <SectionContainer
                            caution
                            contrastBorder
                            title="Remove this stream"
                            underline="Deleting the stream will delete all related resources."
                        >
                            <Button
                                color="error"
                                variant="contained"
                                sx={{ mt: 1 }}
                                size="small"
                                onClick={handleDeleteStream}
                            >
                                Delete this stream
                            </Button>
                        </SectionContainer>
                    </Grid>
                </Fragment>
            }
        </Grid >
    )
}
