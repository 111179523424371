import React, { useEffect, useState } from 'react';

import * as link from '../../utils/helper/link-config';

import world from '../../assets/world-background.png';
import dashboard from '../../assets/analytics.png';

import { makeStyles } from '@mui/styles';
import { alpha, Button, Grid, Typography } from '@mui/material';
import { OpenInNew, ArrowRightAlt, Chat } from '@mui/icons-material';

import SectionHeader from '../../components/global/SectionHeader';
import AnalyticsContainer from '../components/global/AnalyticsContainer';
import MetricsBanner from '../../components/global/MetricsBanner';
import ConcurrentViewer from '../components/metrics/ConcurrentViewer';
import ByteUsage from '../components/metrics/ByteUsage';
import MonthToDate from '../components/metrics/MonthToDate';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    buttonWrapper: {
        marginTop: `${theme.spacing(2)} !important`
    },
    button: {
        marginRight: `${theme.spacing(1)} !important`,
        marginBottom: `${theme.spacing(1)} !important`
    }
}));

export default function Metrics(props) {
    const classes = useStyles();

    document.title = "Metrics | nanoStream Cloud Dashboard"

    const handleButtonClicked = (link) => () => {
        window.open(link, "_blank")
    }
    
    const [usageUnit, setUnit] = useState("Bytes");

    useEffect(() => {
        // fetch the data of local storage for metrics fetch
    }, [])

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <SectionHeader
                    title="Metrics"
                    underline="In the nanoStream Analytics Dashboard you can monitor and analyze your live streams and their performance and quality of service. You can use your Bintu credentials to sign in."
                    interact
                    button="Login to Metrics"
                    clicked={handleButtonClicked(link.METRICS_LOGIN)}
                    icon={<OpenInNew />}
                />
                {/* <div className={classes.buttonWrapper}>
                    <Button
                        className={classes.button}
                        endIcon={<OpenInNew />}
                        variant="contained"
                        onClick={handleButtonClicked(link.METRICS_LOGIN)}
                    >
                        Login to Metrics
                    </Button>
                    <Button
                        className={classes.button}
                        variant="outlined"
                        endIcon={<OpenInNew />}
                        onClick={handleButtonClicked(link.METRICS_DOCS)}
                    >
                        Learn more
                    </Button>
                </div> */}
            </Grid>
            <Grid item xs={12}>
                <MonthToDate />
            </Grid>
            <Grid item xs={12} md={6} pr={{ xs: 0, md: 2 }}>
                <AnalyticsContainer
                    smallTimeRangeFilter
                    // smallCountryRangeFilter
                    title="Concurrent Viewers"
                    underline="Timezone: UTC"
                    info="Time series of maximum concurrent viewer.">
                    <ConcurrentViewer />
                </AnalyticsContainer>
            </Grid>
            <Grid item xs={12} md={6}>
                <AnalyticsContainer
                    smallTimeRangeFilter
                    title= {"Ingest and Playout Usage in " + usageUnit}
                    underline="Timezone: UTC"
                    info="Time series of byte usage for playout and ingest traffic.">
                    <ByteUsage updateUnit={setUnit}/>
                </AnalyticsContainer>
            </Grid>
            {/* <Grid item xs={12} lg={"auto"} mt={2}>
                <MetricsBanner />
            </Grid> */}
        </Grid>

    )
}
