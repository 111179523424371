import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import * as link from '../../../utils/helper/link-config';
import * as m from '../../../utils/helper/methods';

import { makeStyles } from '@mui/styles';
import { Chip, Collapse, Divider, Grid, IconButton, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { CameraOutlined, CopyAllOutlined, LockOutlined, OpenInNew } from '@mui/icons-material';

import CopyButton from '../../global/CopyButton';
import SectionContainer from '../../global/SectionContainer';
import CodeSnippet from '../../player/CodeSnippet';
import MiniPlaybackToken from '../../create/MiniPlaybackToken';
import ContentTable from '../../global/ContentTable';
import IFrameCodeSnippet from '../../player/IFrameCodeSnippet';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    label: {
        color: theme.palette.text.secondary,
    },
}));

export default function NewStreamOverview(props) {
    const classes = useStyles();
    const navigate = useNavigate();
    const stream = props.stream;
    const playerToken = props.token;
    const isSecure = props.secure ? (props.token ? props.secure : false) : false;

    const [redirectRoute, setRedirectRoute] = useState(null)
    const [openSecureTokenPanel, setOpenSecureTokenPanel] = useState(false);
    const [token, setToken] = useState(playerToken);
    const ingest = [
        { label: "RTMP Ingest Streamname", value: stream.ingest.rtmp?.streamname, copy: true },
        { label: "RTMP Ingest Url", value: stream.ingest.rtmp?.url, copy: true }
    ];

    let securitySuffix = isSecure ? `?security.jwtoken=${token}` : "";
    let embedUrlSuffix = stream.playout.rtmp.length > 1 ? `group.id=${stream.id}${isSecure ? `&group.security.jwtoken=${token}` : ""}` : stream.playout.rtmp.map((s, i) => `entry${i > 0 ? i + 1 : ""}.rtmp.streamname=${s.streamname}${isSecure ? `&entry${i > 0 ? i + 1 : ""}.security.jwtoken=${playerToken}` : ""}${s.info ? `&entry${i > 0 ? i + 1 : ""}.info.bitrate=${s.info.bitrate}` : ""}`).join('&')
    const [playoutURL, setPlayoutURL] = useState([
        { label: "Live Playout Url", link: `${link.BASE}${link.PLAYOUT}/${stream.id}${isSecure ? `${securitySuffix}` : ""}`, copy: true },
        { label: "iFrame Embed Url", link: `${link.H5LIVE_EMBED_PLAYER}${embedUrlSuffix}`, copy: true }
    ]);

    const overview = [
        { label: "Stream Info", link: `${link.BASE}${link.STREAM}/${stream.id}`, copy: true }
    ];
    const [hash, setHash] = useState([
        ...(isSecure ? [
            { label: "Token", value: token.token, copy: true },
            { label: "Expires", exp: moment.unix(token.expires).utc().format('MM/DD/YYYY hh:mm a (UTC)') },
            { label: "Tag", tag: token.tag },
            { label: "Options", value: token.options },
        ] : [])
    ]);

    const openInNewTab = () => {
        window.open(link.H5LIVE_DOCS, '_blank')
    }

    const handleRedirect = (link) => () => {
        navigate(link);
    }

    const handleExpandSecureToken = () => {
        setOpenSecureTokenPanel(!openSecureTokenPanel)
    }

    const handleNewSecurityOptions = (newToken) => {
        let decodedToken = m.DECODE_TOKEN(newToken);
        if (decodedToken) {
            setHash([
                { label: "JWT token", value: newToken, copy: true },
                { label: "Not Before (nbf)", nbf: moment.unix(decodedToken.nbf).utc().format('MM/DD/YYYY hh:mm a (UTC)'), copy: false },
                { label: "Expires (exp)", exp: moment.unix(decodedToken.exp).utc().format('MM/DD/YYYY hh:mm a (UTC)'), copy: false },
                ...(decodedToken.orgahash ? [{ label: "Entire Organisation", bool: { is: true }, copy: false }] : []),
                ...(decodedToken.streams ? [{ label: "Stream name(s)", value: `[${decodedToken.streams.map(s => s)?.join(", ")}]`, copy: true }] : []),
                ...(decodedToken.tag ? [{ label: "Tag", tag: decodedToken.tag }] : []),
                ...(decodedToken.ip ? [{ label: "IP", value: decodedToken.ip, copy: true }] : []),
                ...(decodedToken.domain ? [{ label: "Domain", link: decodedToken.domain, copy: true }] : []),
            ]);
        }

        if (token !== newToken) {
            securitySuffix = isSecure ? `?security.jwtoken=${newToken}` : "";
            embedUrlSuffix = isSecure
                ? stream.playout.rtmp.length > 1 ? `group.id=${stream.id}${isSecure ? `&group.security.jwtoken=${newToken}` : ""}` : stream.playout.rtmp.map((s, i) => `entry${i > 0 ? i + 1 : ""}.rtmp.streamname = ${s.streamname}${isSecure ? `&entry${i > 0 ? i + 1 : ""}.security.jwtoken=${newToken}` : ""}${s.info ? `&entry${i > 0 ? i + 1 : ""}.info.bitrate=${s.info.bitrate}` : ""}`).join('&')
                : embedUrlSuffix;
            let newPlayoutList = playoutURL;
            newPlayoutList[0] = { label: "Live Playout Url", link: `${link.BASE}${link.PLAYOUT}/${stream.id}${isSecure ? `${securitySuffix}` : ""}`, copy: true }
            newPlayoutList[1] = { label: "iFrame Embed Url", link: `${link.H5LIVE_EMBED_PLAYER}${embedUrlSuffix}`, copy: true };
            setPlayoutURL(newPlayoutList);
        }

        setToken(newToken);
        setOpenSecureTokenPanel(false);
    }

    useEffect(() => {
        if (isSecure && playerToken) handleNewSecurityOptions(playerToken)
    }, [])

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <SectionContainer
                    title="1. Start the Ingest"
                    underline="Copy the info below to your Live Encoder software or hardware (e.g.OBS), or use the nanoStream Webcaster."
                    button="Open nanoStream Webcaster"
                    interact
                    icon={<CameraOutlined />}
                    clicked={handleRedirect(`${link.WEBCASTER}/${stream.id}`)}
                >
                    <ContentTable data={ingest} />
                </SectionContainer>
            </Grid>
            <Grid item xs={12}>
                <SectionContainer
                    title="2. Access the Web Playout"
                    button="nanoPlayer Documentation"
                    interact
                    icon={<OpenInNew />}
                    clicked={openInNewTab}
                    underline={
                        isSecure
                            ? 'This live playout url contains a standard token that is valid for your entire organization. It is valid for 24 hours. If you want to change the security setup for this stream, create a new token by clicking the "Create new Playback Token" button in 2.1.'
                            : 'Use this live playout url to access the playback of the stream.'
                    }
                >
                    <ContentTable data={playoutURL} />
                </SectionContainer>
            </Grid>
            {
                isSecure
                &&
                <Grid item xs={12}>
                    <SectionContainer
                        title="2.1 Playback Token"
                        underline="This is the playback token that you apply once you click on the web playout link."
                        button="Create new Playback Token"
                        interact
                        icon={<LockOutlined />}
                        clicked={handleExpandSecureToken}
                    >
                        <Collapse in={openSecureTokenPanel}>
                            <Divider sx={{ mt: 2 }} />
                            <MiniPlaybackToken
                                groupid={stream.id}
                                handleUpdateToken={handleNewSecurityOptions}
                            />
                        </Collapse>
                        <ContentTable data={hash} />
                    </SectionContainer>
                </Grid>
            }
            <Grid item xs={12} mt={2}>
                <IFrameCodeSnippet stream={stream} security={token} />
                <CodeSnippet stream={stream} security={token} />
            </Grid>
            <Grid item xs={12}>
                <SectionContainer
                    title="3. Stream Overview"
                >
                    <ContentTable data={overview} />
                </SectionContainer>
            </Grid>
        </Grid >

    )
}
