import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import * as link from '../../utils/helper/link-config';

import { makeStyles } from '@mui/styles';
import { Box, Chip, Divider, Grid, Hidden, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { HighlightOff, More, PlayCircleOutline, PlaylistPlayOutlined, TaskAltOutlined } from '@mui/icons-material';

import State from '../global/State';
import SectionHeader from '../global/SectionHeader';
import SectionContainer from '../global/SectionContainer';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    tag: {
        margin: theme.spacing(0.2, 0.3),
        borderRadius: '5px !important'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
        marginBottom: theme.spacing(1)
    },
    tagWrapper: {
        marginTop: theme.spacing(1)
    },
    tableHead: {
        backgroundColor: `${theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.grey[900]} !important`
    }
}));

export default function LatestStreams(props) {
    const classes = useStyles();
    const [streams, setStreams] = useState(props.streams);
    const navigate = useNavigate();

    const handleSeeAllStreams = () => {
        navigate(link.STREAMS)
    }

    const handleOpenStream = (streamid) => () => {
        navigate(`${link.STREAM}/${streamid}`)
    }

    useEffect(() => {
        setStreams(props.streams)
    }, [props.streams])

    return (
        <Fragment>
            {
                Object.entries(streams).length !== 0
                &&
                <SectionContainer
                    title="Your Latest Streams"
                    underline={`Check out your most recent ${streams.length > 1 ? "streams" : "stream"} here. To see the entire list click on the button above.`}
                    button={"See all streams"}
                    interact
                    icon={<PlaylistPlayOutlined />}
                    clicked={handleSeeAllStreams}
                >
                    <Grid container className={classes.root} mt={1}>
                        <Grid item xs={12}>
                            <TableContainer className={classes.tableWrapper}>
                                <Table stickyHeader size="small">
                                    <TableHead>
                                        <TableRow>
                                            <Hidden mdUp>
                                                <TableCell className={classes.tableHead}>
                                                    <Typography variant="button" color="textSecondary">
                                                        All Streams
                                                    </Typography>
                                                </TableCell>
                                            </Hidden>
                                            <Hidden mdDown>
                                                {
                                                    [
                                                        "State",
                                                        "Stream Id",
                                                        "Stream Name",
                                                        "Streamgroup",
                                                        "Last Updated",
                                                        "Tags",
                                                    ].map((label) => (
                                                        <TableCell className={classes.tableHead} key={label}>
                                                            <Typography variant="button" color="textSecondary">
                                                                {label}
                                                            </Typography>
                                                        </TableCell>
                                                    ))
                                                }
                                            </Hidden>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className={classes.tableBody}>
                                        {
                                            streams
                                            &&
                                            <Fragment>
                                                {
                                                    streams.map((stream) => (
                                                        <Fragment key={stream.id}>
                                                            <Hidden mdUp>
                                                                <TableRow hover sx={{ cursor: 'pointer' }} onClick={handleOpenStream(stream.id)}>
                                                                    <TableCell>
                                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                            <State state={stream.state} streamid={stream.id} />
                                                                            <Divider sx={{ mr: 1, ml: 1 }} orientation="vertical" variant="middle" flexItem />
                                                                            <Typography color="textSecondary" variant="subtitle2" sx={{ lineHeight: 1, textAlign: 'center' }}>
                                                                                {moment(stream.updatedAt).format('MM/DD/YYYY hh:mm a')} (UTC)
                                                                            </Typography>
                                                                            <Divider sx={{ mr: 1, ml: 1 }} orientation="vertical" variant="middle" flexItem />
                                                                            {
                                                                                stream.streamgroup && stream.playout.h5live.length > 1
                                                                                    ? <Chip
                                                                                        size="small"
                                                                                        icon={<TaskAltOutlined />}
                                                                                        label={`Streamgroup (${stream.playout.h5live.length} Playouts)`}
                                                                                        variant="outlined"
                                                                                        color="primary"
                                                                                    />
                                                                                    : <Chip
                                                                                        size="small"
                                                                                        icon={<HighlightOff />}
                                                                                        label={`No streamgroup`}
                                                                                        color="default"
                                                                                    />
                                                                            }
                                                                        </Box>
                                                                        <Box mt={0.5} mb={0.5}>
                                                                            <Typography sx={{ lineHeight: 1 }} variant="body1">
                                                                                {stream.id}
                                                                            </Typography>
                                                                            <Typography mb={1} sx={{ lineHeight: 1 }} variant="button" color="textSecondary">
                                                                                Stream Id
                                                                            </Typography>
                                                                            <Typography sx={{ lineHeight: 1 }} variant="body1">
                                                                                {stream.ingest.rtmp?.streamname}
                                                                            </Typography>
                                                                            <Typography sx={{ lineHeight: 1 }} variant="button" color="textSecondary">
                                                                                Stream Name
                                                                            </Typography>
                                                                        </Box>
                                                                        <Box className={classes.tagWrapper}>
                                                                            {
                                                                                stream.tags.map((tag, i) => (
                                                                                    <Chip
                                                                                        key={i}
                                                                                        size="small"
                                                                                        label={tag}
                                                                                        color="primary"
                                                                                        className={classes.tag}
                                                                                    />
                                                                                ))
                                                                            }
                                                                        </Box>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </Hidden>
                                                            <Hidden mdDown>
                                                                <TableRow hover sx={{ cursor: 'pointer' }}>
                                                                    <TableCell>
                                                                        <State state={stream.state} streamid={stream.id} />
                                                                    </TableCell>
                                                                    <TableCell onClick={handleOpenStream(stream.id)}>
                                                                        <Typography variant="body1">
                                                                            {stream.id}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell onClick={handleOpenStream(stream.id)}>
                                                                        <Typography variant="body1">
                                                                            {stream.ingest.rtmp?.streamname}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {
                                                                            stream.streamgroup && stream.playout.h5live.length > 1
                                                                                ? <Chip
                                                                                    icon={<TaskAltOutlined />}
                                                                                    label={`Streamgroup (${stream.playout.h5live.length} Playouts)`}
                                                                                    variant="outlined"
                                                                                    color="primary"
                                                                                />
                                                                                : <Chip
                                                                                    icon={<HighlightOff />}
                                                                                    label={`No streamgroup`}
                                                                                    color="default"
                                                                                />
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell onClick={handleOpenStream(stream.id)}>
                                                                        <Typography color="textSecondary" variant="subtitle2">
                                                                            {moment(stream.updatedAt).format('MM/DD/YYYY')} <br />
                                                                            {moment(stream.updatedAt).utc().format('hh:mm a')}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell onClick={handleOpenStream(stream.id)}>
                                                                        {
                                                                            stream.tags
                                                                                .filter((a, i) => i < 6)
                                                                                .map((tag, i) => (
                                                                                    <Chip
                                                                                        key={i}
                                                                                        label={tag}
                                                                                        color="primary"
                                                                                        className={classes.tag}
                                                                                    />
                                                                                ))
                                                                        }
                                                                        {
                                                                            stream.tags.length > 6
                                                                            &&
                                                                            <Tooltip arrow title={stream.tags.join(", ")}>
                                                                                <Chip
                                                                                    label={<More />}
                                                                                    color="primary"
                                                                                    className={classes.tag}
                                                                                />
                                                                            </Tooltip>
                                                                        }
                                                                    </TableCell>
                                                                </TableRow>
                                                            </Hidden>
                                                        </Fragment>
                                                    ))
                                                }
                                            </Fragment>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </SectionContainer>
            }
        </Fragment>
    )
}
