import React, { useState } from 'react';

import * as ls from '../../utils/helper/ls-vars';
import * as bintu from '../../utils/bintu/api-requests';

import { makeStyles } from '@mui/styles';
import { Button, Collapse, FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import { Public } from '@mui/icons-material';

import SectionHeader from '../global/SectionHeader';
import { useEffect } from 'react';
import SectionContainer from '../global/SectionContainer';
import SnackbarMessage from '../global/SnackbarMessage';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        padding: theme.spacing(2),
        borderRadius: theme.spacing(0.5),
        background: theme.palette.common.white
    },
    label: {
        fontWeight: `700 !important`,
        fontStyle: "italic"
    }
}));


export default function VodSetting(props) {
    const classes = useStyles();
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});
    const [isPublic, setPublic] = useState(props.publicVod);
    const [updatedPublic, setUpdatedPublic] = useState(isPublic);
    const [update, setUpdate] = useState(false);

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowError(false);
    }

    const handleUpdate = () => {
        setUpdatedPublic(!updatedPublic);
    }

    const handleClickUpdate = () => {
        if (update) setUpdatedPublic(isPublic);
        setUpdate(!update)
    };

    const handleSubmit = () => {
        let data = {
            isPublic: updatedPublic
        }


        bintu.setPublicVOD(data)
            .then((response) => {
                if (response.success) {
                    setUpdatedPublic(response.data.publicVOD);
                    setUpdate(false);
                }
            }).catch((error) => {
                if (!error.success) {
                    setError(error);
                    setShowError(true);
                }
            })
    }

    useEffect(() => {
        setPublic(props.publicVod);
        setUpdatedPublic(props.publicVod);
    }, [])

    return (
        <SectionContainer>
            <SnackbarMessage
                open={showError}
                close={handleCloseError}
                type={"error"}
            >
                <b>Error: {error.code}</b> {error.message}
            </SnackbarMessage>
            <Grid container>
                <Grid item xs={12}>
                    <SectionHeader
                        title="VOD Settings"
                        underline="Select whether you want your organization's VODs to be accessible via the public request URL."
                        interact
                        icon={<Public />}
                        clicked={handleClickUpdate}
                        button={update ? "Cancel" : "Update VOD accessibility"}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={updatedPublic}
                                disabled={!update}
                                onChange={handleUpdate}
                            />
                        }
                        label={
                            <Typography className={classes.label} color="textSecondary" variant="body1">
                                Your VODs are {updatedPublic ? "public" : "not accessible to the public"}
                            </Typography>
                        }
                    />
                </Grid>
                <Grid item xs={12} mt={1}>
                    <Collapse in={update}>
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </Collapse>
                </Grid>
            </Grid>
        </SectionContainer>
    )
}
