import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import * as link from '../utils/helper/link-config';
import * as ls from '../utils/helper/ls-vars';
import * as bintu from '../utils/bintu/api-requests';

import notfound_light from '../assets/StreamNotFound_light.png';
import notfound_dark from '../assets/StreamNotFound_dark.png';

import { makeStyles } from '@mui/styles';
import { Button, Divider, Grid, Typography, useTheme } from '@mui/material';
import { AddCircleOutline, FormatListNumberedOutlined, HeadsetMicOutlined } from '@mui/icons-material';

import SnackbarMessage from '../components/global/SnackbarMessage';
import Loading from '../components/global/Loading';
import StreamOverview from '../components/stream/overview/StreamOverview';
import SectionHeader from '../components/global/SectionHeader';
import ScrollToTop from '../components/global/ScrollToTop';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    image: {
        width: 600,
        maxWidth: '100%',
        margin: '0 auto',
        display: 'block',
        marginBottom: theme.spacing(2)
    },
    item: {
        textAlign: 'center',
    },
    button: {
        margin: `${theme.spacing(1, 1, 0, 0)} !important`
    }
}));

export default function Stream(props) {
    const classes = useStyles();
    const theme = useTheme();
    const navigate = useNavigate();
    const { id } = useParams();

    const anchorId = `${id}-stream-top-anchor`;
    const orga = JSON.parse(localStorage.getItem(ls.BINTU_ORGA));
    const h5liveToken = JSON.parse(localStorage.getItem(ls.H5LIVE_HASH_SECURE));

    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(true);
    const [stream, setStream] = useState(false);

    document.title = `Stream Overview: ${id} | nanoStream Cloud Dashboard`;

    const redirect = (link) => () => {
        navigate(link);
    }

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowError(false);
    }


    const getStreams = () => {
        let data = {
            streamid: id
        }
        bintu.getStreamById(data)
            .then((response) => {
                if (response.success) {
                    setStream(response.data)
                    setLoading(false)
                }
            }).catch((error) => {
                setStream(false);
                setLoading(false);
                if (!error.success) {
                    setError(error);
                    setShowError(true);
                }
            })
    }

    useEffect(() => {
        if (!stream) {
            getStreams();
        }
    }, [])

    return (
        <Grid container className={classes.root} id={anchorId}>
            <SnackbarMessage
                open={showError}
                close={handleCloseError}
                type={"error"}
            >
                <b>Error: {error.code}</b> {error.message}
            </SnackbarMessage>
            {
                loading
                    ? <Loading />
                    :
                    <Fragment>
                        {
                            stream
                                ?
                                <Grid item xs={12}>
                                    <SectionHeader
                                        title="Stream Overview"
                                        underline={`Take a detailed look at the stream with id: ${stream.id}.`}
                                        button="Get instructions"
                                        interact
                                        icon={<FormatListNumberedOutlined />}
                                        clicked={redirect(`${link.NEW_STREAM}/${stream.id}`)}
                                    />
                                    <Divider vertical sx={{ my: 1 }} />
                                    <StreamOverview
                                        stream={stream}
                                        token={h5liveToken}
                                        secure={orga.secure}
                                        orga={orga}
                                    />
                                    <ScrollToTop anchorId={anchorId} />
                                </Grid >
                                :
                                <Grid item xs={12} className={classes.item}>
                                    <img className={classes.image} src={theme.palette.mode === 'light' ? notfound_light : notfound_dark} />
                                    <Typography variant="h5" color="primary" >
                                        Oops, we could not find the stream you are looking for. <br />
                                    </Typography>
                                    <Typography variant="body1" color="textSecondary">
                                        If you think this was a mistake, contact our support.
                                    </Typography>
                                    <div className={classes.buttonWrapper}>
                                        <Button
                                            className={classes.button}
                                            variant="contained"
                                            color="primary"
                                            onClick={redirect(link.CREATE_STREAM)}
                                            startIcon={<AddCircleOutline />}
                                        >
                                            Create new stream
                                        </Button>
                                        <Button
                                            className={classes.button}
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => window.open(link.CONTACT, "_blank")}
                                            startIcon={<HeadsetMicOutlined />}
                                        >
                                            Talk to our support
                                        </Button>
                                    </div>
                                </Grid>
                        }
                    </Fragment >
            }
        </Grid >
    )
}
