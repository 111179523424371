import axios from 'axios';
import moment from 'moment';
import * as link from '../../utils/helper/link-config';
import * as ls from '../../utils/helper/ls-vars';
import * as m from '../../utils/helper/methods';
import * as bintu from '../../utils/bintu/api-requests';

export const METRICS_API = link.METRICS_API;
export const TOKEN_API = link.CTS_PROD;

const getTokenFromLocalStorage = async () => {
    let metricsToken = localStorage.getItem(ls.METRICS_TOKEN);
    let decodedMetricsToken = m.DECODE_TOKEN(metricsToken);
    let isInvalid = (metricsToken && decodedMetricsToken) ? moment.utc(decodedMetricsToken.exp * 1000).isBefore(moment.utc()) : true;
    if (isInvalid) {
        let apikey = bintu.getApiKeyFromLocalStorage();
        try {
            let response = await authWithBintu({ apikey });
            if (response.success) {
                localStorage.setItem(ls.METRICS_TOKEN, response.data.token);
                return response.data.token;
            }
        } catch (error) {
            if (!error.success) {
                return false;
            }
        }
    }
    return metricsToken || false;
}

export const authWithBintu = ({ apikey, exp, nbf }) => {
    const aud = [ "bintu","metrics", "guardian"];
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            url: `${TOKEN_API}/token`,
            headers: {
                'Content-Type': 'application/json',
                'X-BINTU-APIKEY': apikey
            },
            data: { nbf, exp, aud }
        };

        axios.request(options)
            .then(function (response) {
                resolve({ success: response.data.success, data: response.data.data });
            }).catch(function (error) {
                let response = {
                    success: false,
                    ...error.response.data.error
                }
                reject(response);
            });
    });
}

export const authenticate = ({ email, password }) => {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            url: `${METRICS_API}/authenticate`,
            headers: { 'Content-Type': 'application/json' },
            data: {
                email,
                password
            }
        };

        axios.request(options)
            .then(function (response) {
                resolve({ success: response.data.success, data: response.data.data });
            }).catch(function (error) {
                let response = {
                    success: false,
                    ...error.response.data.error
                }
                reject(response);
            });
    });
}

export const getConcurrentViewer = async ({ from, to, interval }) => {
    let token = await getTokenFromLocalStorage();
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET',
            url: `${METRICS_API}/v2/viewers/concurrent/timeseries`,
            params: {
                from, to, interval
            },
            headers: {
                'x-access-token': token
            }
        };

        axios.request(options).then(function (response) {
            resolve({ success: true, data: response.data.data });
        }).catch(function (error) {
            let response = {
                success: false,
                ...error.response.data.error
            }
            reject(response);
        });
    });
}

export const getByteUsage = async ({ from, to, interval }) => {
    let token = await getTokenFromLocalStorage();
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET',
            url: `${METRICS_API}/v2/usage/bytes/timeseries`,
            params: {
                from, to, interval
            },
            headers: {
                'x-access-token': token
            }
        };

        axios.request(options).then(function (response) {
            resolve({ success: true, data: response.data.data });
        }).catch(function (error) {
            let response = {
                success: false,
                ...error.response.data.error
            }
            reject(response);
        });
    });
}

export const getUsageLastMonth = async () => {
    let token = await getTokenFromLocalStorage();
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET',
            url: `${METRICS_API}/v2/usage/lastmonth`,
            headers: {
                'x-access-token': token
            }
        };

        axios.request(options).then(function (response) {
            resolve({ success: true, data: response.data.data });
        }).catch(function (error) {
            let response = {
                success: false,
                ...error.response.data.error
            }
            reject(response);
        });
    });
}

export const getByteUsageHourly = async ({ from, to, interval }) => {
    let token = await getTokenFromLocalStorage();
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET',
            url: `${METRICS_API}/v2/usage/hourly/bytes/timeseries`,
            params: {
                from, to, interval
            },
            headers: {
                'x-access-token': token
            }
        };

        axios.request(options).then(function (response) {
            resolve({ success: true, data: response.data.data });
        }).catch(function (error) {
            let response = {
                success: false,
                ...error.response.data.error
            }
            reject(response);
        });
    });
}

export const getIngestAlertsAndAdvices = async () => {
    let token = await getTokenFromLocalStorage();
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET',
            url: `${METRICS_API}/v2/alerting/ingest`,
            headers: {
                'x-access-token': token
            }
        };

        axios.request(options).then(function (response) {
            resolve({
                success: true,
                data: {
                    ...response.data.data,
                    timestamp: response.data.timestamp,
                    alertDetectionIntervalInMinutes: response.data.alertDetectionIntervalInMinutes
                }
            });
        }).catch(function (error) {
            let response = {
                success: false,
                ...error.response.data.error
            }
            reject(response);
        });
    });
}

export const getMetricsTroubleshootingRedirect = async ({ streamname }) => {
    let token = await getTokenFromLocalStorage();
    const timestamp = encodeURIComponent(Math.floor(Date.now() / 1000));

    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET',
            url: `${METRICS_API}/v2/redirect/troubleshooting`,
            params: {
                timestamp,
                streams: streamname,
            },
            headers: {
                'x-access-token': token
            },
        };

        axios.request(options).then(function (response) {
            resolve({ success: true, data: response.data });
        }).catch(function (error) {
            let response = {
                success: false,
                ...error.response.data.error
            }
            reject(response);
        });
    });
}
