import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment';

import * as m from '../../../utils/helper/methods';
import * as link from '../../../utils/helper/link-config';

import { makeStyles } from '@mui/styles';
import { useTheme } from '@emotion/react';
import { Button, Chip, Divider, Grid, IconButton, ListItemIcon, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { CalendarMonth, CalendarMonthOutlined, CancelOutlined, CheckCircle, CheckCircleOutline, DeleteOutline, HighlightOff, Lock, LockOutlined, MoreHorizOutlined, NotInterested, Send, StopCircle, StopCircleOutlined, TaskAltOutlined } from '@mui/icons-material';
import { Box } from '@mui/system';

import State from '../../global/State';
import CopyButton from '../../global/CopyButton';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    }
}));


export default function StreamUnderline(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    let stream = props.stream;
    const open = Boolean(anchorEl);
    const options = [
        { icon: <StopCircleOutlined />, label: "Lock Stream", id: "Lock" },
        { icon: <DeleteOutline />, label: "Delete Stream", id: "Delete" },
    ];


    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickedMenu = (id) => () => {
        props.edit(id);
        setAnchorEl(null);
    }

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    return (
        <Grid container justifyContent={"space-between"} alignItems={"flex-start"}>
            <Grid item xs={11} sm={"auto"}>
                <Grid container alignItems={'center'} flexWrap={'wrap'} spacing={1}>
                    <Grid item>
                        <State state={stream.state} />
                    </Grid>
                    <Grid item>
                        <Divider orientation="vertical" variant="middle" flexItem style={{ height: theme.spacing(2) }} />
                    </Grid>
                    <Grid item>
                        <Tooltip title="Created at">
                            <Typography variant="body1" color="textSecondary">
                                <CalendarMonthOutlined sx={{ fontSize: 12 }} /> {moment(stream.created_at).utc().format('MM/DD/YYYY hh:mm a')} (UTC)
                            </Typography>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Divider orientation="vertical" variant="middle" flexItem style={{ height: theme.spacing(2) }} />
                    </Grid>
                    <Grid item>
                        {
                            stream.streamgroup && stream.playout.h5live.length > 1
                                ? <Chip
                                    size="small"
                                    icon={<TaskAltOutlined />}
                                    label={`Streamgroup (${stream.playout.h5live.length} Playouts)`}
                                    variant="outlined"
                                    color="primary"
                                />
                                : <Chip
                                    size="small"
                                    icon={<HighlightOff />}
                                    label={`No streamgroup`}
                                    color="default"
                                />
                        }
                    </Grid>
                    {
                        props.isSecure
                        &&
                        <>
                            <Grid item>
                                <Divider orientation="vertical" variant="middle" flexItem style={{ height: theme.spacing(2) }} />
                            </Grid>
                            <Grid item>
                                <Typography variant="body1" color="primary">
                                    <LockOutlined sx={{ fontSize: 12 }} color="primary" />  Secure Stream
                                </Typography>
                            </Grid>
                        </>
                    }
                </Grid>
            </Grid>
            <Grid item xs={1} sm="auto">
                <IconButton size="small" onClick={handleOpenMenu}>
                    <MoreHorizOutlined />
                </IconButton>
                <Menu
                    id="long-menu"
                    MenuListProps={{
                        'aria-labelledby': 'long-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleCloseMenu}
                    PaperProps={{
                        style: {
                            maxHeight: 48 * 4.5,
                            width: '20ch',
                        },
                    }}
                >
                    {options.map((option) => (
                        <MenuItem key={option.label} onClick={handleClickedMenu(option.id)}>
                            <ListItemIcon>
                                {option.icon}
                            </ListItemIcon>
                            <Typography variant="inherit">{option.label}</Typography>
                        </MenuItem>
                    ))}
                </Menu>
            </Grid>
        </Grid>
    )
}