import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import * as link from '../utils/helper/link-config';
import * as ls from '../utils/helper/ls-vars';
import * as bintu from '../utils/bintu/api-requests';

import { makeStyles } from '@mui/styles';
import { Button, Collapse, Grid } from '@mui/material';
import { AddOutlined, OpenInNewOutlined, RemoveOutlined } from '@mui/icons-material';

import SectionHeader from '../components/global/SectionHeader';
import TokenTarget from '../components/create/TokenTarget';
import TokenDate from '../components/create/TokenDate';
import AddSpecificOption from '../components/create/AddSpecificOption';
import AddTags from '../components/create/AddTags';
import SnackbarMessage from '../components/global/SnackbarMessage';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    button: {
        marginRight: `${theme.spacing(1)} !important`,
    }
}));

export default function CreatePlaybackToken(props) {
    const classes = useStyles();
    const apikey = localStorage.getItem(ls.BINTU_APIKEY);
    const navigate = useNavigate();

    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});

    const [openMoreOptions, setOpenMoreOptions] = useState(false);

    const [target, setTarget] = useState({});

    const [timerange, setTimerange] = useState({
        exp: new Date(Date.now() + 12096e5)
    });
    const [option, setOption] = useState(null);
    const [tag, setTag] = useState(null);

    document.title = "Create Secure Playback Token | nanoStream Cloud Dashboard";

    const handleClickedLearnMore = () => {
        window.open(link.TOKEN_SECURITY_DOCS, '_blank')
    }

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowError(false);
    }

    const handleOpenMoreOptions = () => {
        setOpenMoreOptions(!openMoreOptions);
    }

    const handleUpdateTarget = (newTarget) => {
        setTarget(newTarget);
    }

    const handleUpdateDate = (date) => {
        let updatedDate = { ...timerange, ...date };
        setTimerange(updatedDate);
    }

    const handleUpdateSpecificOption = (newOption) => {
        setOption({
            ...option,
            ...newOption
        })
    }

    const handleUpdateTagList = (tagList) => {
        if (tagList.length > 0) {
            setTag({ tag: tagList[0] });
        }
    }

    const handleCreateToken = () => {
        let exp = { exp: Math.floor(timerange.exp.getTime() / 1000) }

        let data = {
            apikey,
            data: {
                ...target,
                ...exp,
                ...(timerange.nbf && { nbf: Math.floor(timerange.nbf.getTime() / 1000) }),
                ...(option && option),
                ...(tag && tag)
            }
        }

        bintu.createH5LiveSecureToken(data)
            .then((response) => {
                if (response.success) {
                    navigate(`${link.PLAYBACK_TOKEN}/${response.data.data.token}`)
                }
            }).catch((error) => {
                if (!error.success) {
                    setError(error);
                    setShowError(true);
                }
            })
    }


    return (
        <Grid container className={classes.root}>
            <SnackbarMessage
                open={showError}
                close={handleCloseError}
                type={"error"}
            >
                <b>Error: {error.errorCode}</b> {error.message}
            </SnackbarMessage>
            <Grid item xs={12}>
                <SectionHeader
                    title="Create secure playback token"
                    underline="Generate tokens to enable nanoStream H5Live secure playbacks."
                    interact
                    button="Learn more"
                    icon={<OpenInNewOutlined />}
                    clicked={handleClickedLearnMore}
                />
            </Grid>
            <Grid item xs={12}>
                <TokenTarget updateTarget={handleUpdateTarget} />
            </Grid>
            <Grid item xs={12}>
                <TokenDate
                    title="Set expiration date"
                    id="exp"
                    default={timerange.exp}
                    underline="Set the expiration date of your secure token. The default is two weeks."
                    updateDate={handleUpdateDate}
                />
            </Grid>
            <Grid item xs={12} mt={2}>
                <Button
                    className={classes.button}
                    size="small"
                    variant="outlined"
                    onClick={handleOpenMoreOptions}
                    startIcon={openMoreOptions ? <RemoveOutlined /> : <AddOutlined />}
                >
                    Add more specific options
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Collapse in={openMoreOptions}>
                    <Grid container>
                        <Grid item xs={12} md={6} sx={{ pr: { md: 2 } }}>
                            <TokenDate
                                title="Set not before date (optional)"
                                id="nbf"
                                underline="Set the not before date of your secure token. It can't be before now."
                                updateDate={handleUpdateDate}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <AddSpecificOption
                                id="ip"
                                placeholder="1.1.1.1"
                                title="Add a client IP (optional)"
                                update={handleUpdateSpecificOption}
                                underline="You can restrict the access/playback of a stream to a specific IP."
                            />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ pr: { md: 2 } }}>
                            <AddSpecificOption
                                id="domain"
                                placeholder="your-domain.net"
                                title="Add a domain (optional)"
                                update={handleUpdateSpecificOption}
                                underline="You can restrict the access/playback of a stream to a specific domain."
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <AddSpecificOption
                                id="userid"
                                placeholder="7365zdue7"
                                title="Add a user id (optional)"
                                update={handleUpdateSpecificOption}
                                underline="Add a user id to your token."
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AddTags
                                title="Add a single tag (optional)"
                                underline="Add a tag to track your token."
                                updateTagList={handleUpdateTagList}
                            />
                        </Grid>
                    </Grid>
                </Collapse>
                <Grid item xs={12} mt={2}>
                    <Button
                        size="small"
                        variant="contained"
                        className={classes.button}
                        color="primary"
                        onClick={handleCreateToken}
                    >
                        Create new token
                    </Button>
                    {/* <Button
                        className={classes.button}
                        size="small"
                        variant="inherit"
                        color="secondary"
                        startIcon={<RestartAltOutlined/>}
                        onClick={handleReset}
                    >
                        Reset
                    </Button> */}
                </Grid>
            </Grid>
        </Grid >
    )
}
