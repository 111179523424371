import React, { useState, useEffect, Fragment } from 'react';

import { makeStyles } from '@mui/styles';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Divider, Grid, Hidden, Typography } from '@mui/material';
import { ArrowForwardIosSharp, AspectRatio, AutoAwesomeMotionOutlined, FilterCenterFocusOutlined, GraphicEqOutlined, NetworkCheck, QueryStatsOutlined, VideoSettingsOutlined } from '@mui/icons-material';

import CopyButton from '../global/CopyButton';
import SectionContainer from '../global/SectionContainer';
import SectionHeader from '../global/SectionHeader';
import ContentTable from '../global/ContentTable';


const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    icon: {
        fontSize: '12px !important',
        marginRight: 0.2
    },
    statWrapper: {
        borderRadius: theme.spacing(0.5),
        textAlign: "center",
        background: theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.grey[900],
        "&>svg": {
            color: theme.palette.primary.main,
        }
    },
}));


export default function PlayerStats(props) {
    const classes = useStyles();
    const [infos, setInfos] = useState(props.stats);
    const [res, setRes] = useState(false);
    const [currentStream, setCurrentStream] = useState(false);

    useEffect(() => {
        setInfos(props.stats)
    }, [props.stats])

    useEffect(() => {
        let streamInfo = props.streamInfo
        if (streamInfo.haveVideo) {
            let resolution = `${streamInfo.videoInfo.width}x${streamInfo.videoInfo.height}`;
            if (resolution !== res) setRes(resolution)
        }
        if (streamInfo.rtmp) {
            let streamname = streamInfo.rtmp.streamname;
            if (streamname !== currentStream) setCurrentStream(streamname);
        }
    }, [props.streamInfo])

    return (
        <Fragment>
            <Hidden mdDown>
                <Grid container>
                    <Grid item xs={12}>
                        <Accordion defaultExpanded className={classes.accordionRoot}>
                            <AccordionSummary
                                sx={{ flexDirection: 'row-reverse', alignItems: 'center', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}
                                expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} />}
                            >
                                <Typography variant="body1" ml={1}>
                                    Player Statistics
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ContentTable data={[
                                    { icon: <VideoSettingsOutlined className={classes.icon} />, label: "State", value: props.playerState },
                                    ...(currentStream ? [{ icon: <FilterCenterFocusOutlined className={classes.icon} />, label: "Current Stream", value: currentStream }] : []),
                                    ...(res ? [{ icon: <AspectRatio className={classes.icon} />, label: "Resolution", value: res }] : []),
                                    { icon: <NetworkCheck className={classes.icon} />, label: "Play Latency", value: `${(infos.buffer.delay.avg).toFixed(2)}s` },
                                    { icon: <AutoAwesomeMotionOutlined className={classes.icon} />, label: "Framerate", value: `${(infos.framerate.avg).toFixed(2)}fps` },
                                    { icon: <GraphicEqOutlined className={classes.icon} />, label: "Bitrate", value: `${(infos.bitrate.avg / 1000).toFixed(0)}kbps` }
                                ]} />
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            </Hidden>
            <Hidden mdUp>
                <SectionContainer noMargin title="Player Statistics">
                    <Divider sx={{ mt: 1, mb: 1 }} />
                    <Grid container>
                        {
                            [
                                { icon: <VideoSettingsOutlined />, label: "Player State", value: props.playerState },
                                ...(currentStream ? [{ icon: <FilterCenterFocusOutlined />, label: "Current Stream", value: currentStream }] : []),
                                ...(res ? [{ icon: <AspectRatio />, label: "Resolution", value: res }] : []),
                                { icon: <NetworkCheck />, label: "Play Latency", value: `${(infos.buffer.delay.avg).toFixed(2)}s` },
                                { icon: <AutoAwesomeMotionOutlined />, label: "Framerate", value: `${(infos.framerate.avg).toFixed(2)}fps` },
                                { icon: <GraphicEqOutlined />, label: "Bitrate", value: `${(infos.bitrate.avg / 1000).toFixed(0)}kbps` }
                            ].map((item) => (
                                <Grid item mr={2} xs="auto" mb={0.5}>
                                    <Typography color="primary" variant="body2" sx={{ lineHeight: '1.1' }}>
                                        {item.value}
                                    </Typography>
                                    <Typography variant="subtitle2" sx={{ lineHeight: '1.1' }}>
                                        {item.label}
                                    </Typography>
                                </Grid>
                            ))
                        }
                    </Grid>
                </SectionContainer>
            </Hidden>
        </Fragment>


    )
}
