import React, { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import * as ls from '../utils/helper/ls-vars';
import * as link from '../utils/helper/link-config';
import * as support from '../utils/helper/support';
import * as m from '../utils/helper/methods';

import streams from '../assets/streams-background.jpeg';
import datagrid from '../assets/datagrid_background.jpeg';
import world from '../assets/world-background.jpeg';
import dashboard from '../assets/city-background.jpeg'

import { makeStyles } from '@mui/styles';
import { Button, Divider, Grid, Tab, Tabs, Typography } from '@mui/material';
import { AccountCircleOutlined, HelpOutline, OpenInNew, PieChartOutline, PlayCircleOutline, ReadMoreOutlined } from '@mui/icons-material';

import SectionHeader from '../components/global/SectionHeader';
import HelperContent from '../components/about/HelperContent';
import ArticleCard from '../components/global/ArticleCard';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            hidden={value !== index}
        >
            {value === index && (
                <Fragment>
                    {children}
                </Fragment>
            )}
        </div>
    );
}

export default function Support(props) {
    const classes = useStyles();
    const navigate = useNavigate();
    const orga = JSON.parse(localStorage.getItem(ls.BINTU_ORGA));
    const [index, setIndex] = useState(0);

    document.title = "Help Center | nanoStream Cloud Dashboard";

    const handleGetSupport = () => {
        let supportUrl = `${link.NANO_SUPPORT}?bintu.id=${orga ? orga.id : ""}&bintu.name=${orga ? orga.name : ""}&bintu.webhook=${orga ? orga.webhook : ""}&bintu.vod=${orga ? orga.vod : ""}&bintu.webrtc=${orga ? orga.webrtc : ""}&bintu.transcoding=${orga ? orga.transcoding : ""}&bintu.h5live=${orga ? orga.h5live : ""}&bintu.hash=${orga ? orga.hash : ""}&bintu.hls=${orga ? orga.hls : ""}&bintu.secure=${orga ? orga.secure : ""}&bintu.metrics=${orga ? orga.metrics : ""}&bintu.sla=${orga ? orga.sla : ""}&bintu.trial=${orga ? orga.trial : ""}`
        window.open(supportUrl, "_blank")
    }

    const handleLinkClicked = (link) => () => {
        navigate(link);
    }

    const handleOpenExternal = (link) => () => {
        window.open(link, "_blank")
    }


    const handleUpdateIndex = (event, newIndex) => {
        setIndex(newIndex);
    }

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12} mb={2}>
                <SectionHeader
                    interact
                    title="Help Center"
                    underline="Get support in implementing anything you want to achieve within the nanoStream Cloud. Have a look at our rich documentation to find out the answers to your questions. If your question marks don't turn into exclamation marks, contact our support."
                    button="Get support"
                    clicked={handleGetSupport}
                    icon={<OpenInNew />}
                />
            </Grid>
            <Grid item xs={12}>
                <Tabs value={index} onChange={handleUpdateIndex}>
                    <Tab label="Useful Resources" />
                    {/* <Tab label="FAQ" /> */}
                    <Tab label="Getting Started" />
                </Tabs>
                <Divider />
                <TabPanel value={index} index={0}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} mt={2} mb={1}>
                            <SectionHeader
                                title="nanoStream Cloud Customer Documentation"
                                underline="Browse the latest developer documentation, including sample code, tutorials, and API reference."
                            />
                        </Grid>
                        {
                            Object.values(support.SUPPORT).map((content, index) => (
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <ArticleCard
                                        icon={content.icon}
                                        title={content.title}
                                        underline={content.underline}
                                    >
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            endIcon={<OpenInNew />}
                                            onClick={handleOpenExternal(content.link)}
                                        >
                                            Open Docs
                                        </Button>
                                    </ArticleCard>
                                </Grid>

                            ))
                        }
                    </Grid>
                </TabPanel>
                <TabPanel value={index} index={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} mt={2} mb={1}>
                            <SectionHeader
                                title="Getting started"
                                underline="To help you get started with the nanoStream Cloud, we've put together a range of helpful resources!"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <ArticleCard
                                image={streams}
                                title="How to setup your live stream"
                                underline="Watch this tutorial to learn how to create and start a stream. Our expert will guide you through this process within a few clicks."
                            >
                                <Button
                                    variant="outlined"
                                    size="small"
                                    endIcon={<PlayCircleOutline />}
                                    onClick={handleOpenExternal(link.GETTING_STARTED_CREATE_STREAM)}
                                >
                                    Watch Video
                                </Button>
                            </ArticleCard>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <ArticleCard
                                image={dashboard}
                                title="Getting Started with the Dashboard"
                                underline="Have a look into our docs to learn how to use the nanoStream Cloud Dashboard."
                            >
                                <Button
                                    variant="outlined"
                                    size="small"
                                    endIcon={<OpenInNew />}
                                    onClick={handleOpenExternal(link.GETTING_STARTED_CREATE_STREAM)}
                                >
                                    Learn more
                                </Button>
                            </ArticleCard>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <ArticleCard
                                image={datagrid}
                                title="Analytics for your streams"
                                underline="Know how your streams are performing, and analyse the data at the tip of your fingers! Experience for yourself how it can help you."
                            >
                                <Button
                                    variant="outlined"
                                    size="small"
                                    endIcon={<PieChartOutline />}
                                    onClick={handleOpenExternal(link.METRICS_DOCS)}
                                >
                                    Read more
                                </Button>
                            </ArticleCard>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <ArticleCard
                                image={world}
                                title="Learn more"
                                underline="You can find an overview of further links to each component of the nanoStream Cloud here. These include the API, blog articles and samples."
                            >
                                <Button
                                    variant="outlined"
                                    size="small"
                                    endIcon={<HelpOutline />}
                                    onClick={handleLinkClicked(link.SUPPORT)}
                                >
                                    Help Center
                                </Button>
                            </ArticleCard>
                        </Grid>
                    </Grid>
                </TabPanel>
            </Grid>

        </Grid>
    )
}
