import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';

import { timeDateConverter } from '../../utils/helper/time-converter';
import { convertBytes } from '../../utils/helper/byte-formatter';

import { makeStyles } from '@mui/styles';
import { Box, Chip, Grid, Skeleton, Typography, alpha, useTheme } from '@mui/material';

import * as metrics from '../../utils/api-requests';

import SectionContainer from '../../../components/global/SectionContainer';

import { CallMade, CallReceived, KeyboardArrowDown, KeyboardArrowUp, NorthEastRounded, NorthWest, NorthWestRounded, SouthEast, SouthEastRounded, SouthWest, TrendingDown, TrendingUp } from '@mui/icons-material';
import SnackbarMessage from '../../../components/global/SnackbarMessage';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {},
    headerWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    }
}));

const SmallCard = (props) => {
    const theme = useTheme();

    return (
        <SectionContainer>
            {
                <Grid item xs={12}>
                    {
                        props.loading
                            ? <Skeleton width={150} />
                            :
                            <Fragment>
                                <Typography
                                    mr={1} variant="button"
                                    color="textSecondary"
                                    sx={{ lineHeight: 1 }}
                                >
                                    {props.label} {props.thisMonth}
                                </Typography>
                                {!isNaN(props.chip) && !(props.chip == 0) && (
                                    <Typography variant="button" sx={{ fontWeight: 700, lineHeight: 1, color: props.chip > -1 ? theme.palette.success.main : theme.palette.error.main }}  >
                                        {
                                            props.chip > -1
                                                ? <CallMade fontSize="inherit" />
                                                : <SouthEast fontSize="inherit" />
                                        }
                                        {`${props.chip > 0 ? '+' : ''}${props.chip}%`}
                                    </Typography>
                                )}
                            </Fragment>
                    }
                </Grid>
            }
            <Grid item xs={12} sx={{ color: theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.common.white }}>
                <Typography variant="h3" sx={{ fontWeight: 700, pr: 0.5, display: 'inline-block' }}>
                    {props.loading ? <Skeleton width={30} /> : props.thisData}
                </Typography>
                <Typography variant="button" sx={{ display: 'inline-block' }}>
                    {props.unit}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {
                    props.loading
                        ? <Skeleton />
                        : <Typography variant="body2" color="textSecondary" sx={{ display: 'inline-block', lineHeight: 1 }}>
                            {props.label} in {props.prevMonth}: {props.prevData}
                        </Typography>
                }
            </Grid>
        </SectionContainer >
    );
};

export default function MonthToDate(props) {
    const classes = useStyles();
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(true);

    const thisMonth = moment().format('MMMM');
    const prevMonth = moment().subtract(1, 'month').format('MMMM');
    let interval = "month";

    const [totalData, setData] = useState([
        { id: "total", label: 'Usage', prev: 0, now: 0 },
        { id: "playout", label: 'Playout', prev: 0, now: 0 },
        { id: "ingest", label: 'Ingest', prev: 0, now: 0 }
    ]);

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowError(false);
    }

    const calculateByteDifference = (prevBytes, noBytes) => {
        if (prevBytes === 0) {
            return noBytes === 0 ? '0.0' : '100.0';
        }
        return (((noBytes - prevBytes) / prevBytes) * 100).toFixed(0);
    };

    const getTotalUsage = (filter, type) => {
        metrics
            .getByteUsageHourly(filter)
            .then((response) => {
                if (response.success) {
                    let result = response.data[0];
                    let newData = {
                        ingest: result.ingest.bytes,
                        playout: result.playout.bytes,
                        total: result.ingest.bytes + result.playout.bytes
                    }

                    setData(prevData => prevData.map(item => {
                        if (Object.keys(newData).includes(item.id)) {
                            return { ...item, ...prevData, [type]: newData[item.id] };
                        }
                        return { ...totalData, ...item };
                    }));
                    setLoading(false);
                }
            })
            .catch(() => {
                if (!error.success) {
                    setError(error);
                    setShowError(true);
                    setLoading(false);
                }
            });
    }

    useEffect(() => {
        const now = moment().utc();
        const lastMonthFilter = {
            from: timeDateConverter.lastMonthToDate.start(now).format('YYYY-MM-DDTHH:mm'),
            to: timeDateConverter.lastMonthToDate.end(now).format('YYYY-MM-DDTHH:mm'),
            interval
        };
        const currentMonthFilter = {
            from: timeDateConverter.currentMonth.start(now).format('YYYY-MM-DDTHH:mm'),
            to: timeDateConverter.currentMonth.end(now).format('YYYY-MM-DDTHH:mm'),
            interval
        };
        getTotalUsage(lastMonthFilter, "prev");
        getTotalUsage(currentMonthFilter, "now");
    }, []);

    return (
        <Grid container className={classes.root}>
            <SnackbarMessage
                open={showError}
                close={handleCloseError}
                type={"error"}
            >
                <b>Error: {error.code}</b> {error.message}
            </SnackbarMessage>
            {totalData.map((data, i) => (
                <Grid
                    item xs={12} sm={"auto"}
                    sx={{ pr: { sm: 1.5 } }}
                >
                    <SmallCard
                        loading={loading}
                        label={`Total ${data.label}`}
                        thisMonth={thisMonth}
                        prevMonth={prevMonth}
                        thisData={convertBytes(data.now).value}
                        prevData={convertBytes(data.prev).value + ' ' + convertBytes(data.prev).unit}
                        unit={convertBytes(data.now).unit}
                        chip={calculateByteDifference(data.prev, data.now)}
                    // orange={i === 0 && true}
                    />
                </Grid>
            ))
            }
        </Grid >
    );
}
