import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import * as link from '../../utils/helper/link-config';
import * as bintu from '../../utils/bintu/api-requests';
import * as ls from '../../utils/helper/ls-vars';
import * as metrics from '../../analytics/utils/api-requests';

import { makeStyles } from '@mui/styles';
import { Button, Checkbox, Collapse, FormControlLabel, Grid, InputAdornment, Link, Stack, TextField, Typography } from '@mui/material';
import { ArrowRightAlt, Lock, Mail } from '@mui/icons-material';

import SnackbarMessage from '../global/SnackbarMessage';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    header: {
        color: theme.palette.common.white,
    },
    accent: {
        color: theme.palette.primary.main,
        fontWeight: 700
    },
    underline: {
        color: theme.palette.common.white,
        fontWeight: 400
    },
    textFieldWrapper: {
        margin: theme.spacing(2, 0)
    },
    buttonWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: theme.spacing(2)
    },
    textField: {
        '& .NanoInputBase-root': {
            color: theme.palette.common.white,
        }
    },
    optionWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    forgotPassword: {
        cursor: 'pointer'
    }
}));

export default function SignIn(props) {
    const classes = useStyles();
    let mailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const [checkedTerms, setCheckedTerms] = useState(false);
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});
    const [forgotPassword, setForgotPassword] = useState(false);
    const navigate = useNavigate();
    const [textfields, setTextfields] = useState({
        email: {
            value: "",
            id: "email",
            autoComplete: "email",
            label: "E-Mail",
            type: "text",
            error: false,
            icon: <Mail color="primary" />,
            show: true
        },

        password: {
            value: "",
            id: "password",
            autoComplete: "password",
            label: "Password",
            type: "password",
            error: false,
            icon: <Lock color="primary" />,
            show: true
        },
    });

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowError(false);
    }

    const handleUpdateTextfield = (id) => (event) => {
        let updatedValue = event.target.value
        setTextfields({
            ...textfields,
            [id]: {
                ...textfields[id],
                value: updatedValue
            }
        })
    }

    const handleSubmitTextfield = (event) => {
        event.preventDefault();
        handleLogin(event);
    }


    const handleAcceptTerms = () => {
        if (!checkedTerms && showError) {
            setShowError(false);
        }
        setCheckedTerms(!checkedTerms);
    }

    const handleAnyKeyPressed = (event) => {
        let code = event.charCode
        switch (code) {
            case 13: handleLogin(event); break;
            default: break;
        }
    }

    const credentialsAreInvalid = () => {
        let mailInvalid = !mailRegex.test(textfields.email.value)
        let passwordInvalid = textfields.password.value === "";

        setTextfields({
            ...textfields,
            email: {
                ...textfields.email,
                error: mailInvalid
            },
            ...(!forgotPassword && {
                password: {
                    ...textfields.password,
                    error: passwordInvalid
                }
            }),
        });

        if (forgotPassword) return mailInvalid;

        if (!checkedTerms) {
            setError({ type: "info", message: "To use the nanoStream Cloud dashboard, you need to accept our terms and conditions." });
            setShowError(true);
        }

        return mailInvalid || passwordInvalid || !checkedTerms;
    }

    const resetPassword = () => {
        let data = {
            email: textfields.email.value
        }
        bintu.resetPassword(data)
            .then((response) => {
                if (response.success) {
                    handleForgotPassword();
                    return;
                }
            }).catch((error) => {
                if (!error.success) {
                    setError(error);
                    setShowError(true);
                }
            })
    }


    const redirect = (link) => {
        navigate(link);
    }

    const successfulLogin = () => {
        window.dispatchEvent(new Event("storage")); // Register storage changes
        redirect(link.DASHBOARD); // Redirect to Dashboard
    }

    const handleLogin = (event) => {
        event.preventDefault();


        if (credentialsAreInvalid()) return;

        if (forgotPassword) {
            resetPassword();
            return;
        }

        // TO DO LOGIN USER
        let userCredentials = {
            email: textfields.email.value,
            password: textfields.password.value
        }

        bintu.validate(userCredentials)
            .then((response) => {
                if (response.success) {
                    let data = response.data
                    let apikey = data.apiKey

                    localStorage.setItem(ls.AUTHORIZED, true);
                    localStorage.setItem(ls.BINTU_APIKEY, apikey)
                    localStorage.setItem(ls.BINTU_MAIL, textfields.email.value);
                    // SAVE TOKEN FOR METRICS AUTHENTICATION
                    localStorage.setItem(ls.METRICS_TOKEN, response.data.token);

                    let exp = Math.round(moment(new Date(Date.now())).add(24, 'hours') / 1000);

                    bintu.getOrganisation(apikey)
                        .then((response) => {
                            if (response.success) {
                                let res = response.data
                                if (res.isEnabled) {
                                    localStorage.setItem(ls.BINTU_ORGA, JSON.stringify(res));
                                }
                                if (!res.isEnabled) {
                                    setError({ type: 'warning', message: "Your orga has been disabled. Please contact our sales team via our contact form nanocosmos.de/contact." });
                                    setShowError(true);
                                    return;
                                }
                                if (res.secure) {
                                    let data = {
                                        tag: "nanostream-cloud-dashboard-player-token",
                                        exp: exp,
                                        orgawide: true
                                    };
                                    bintu.createH5LiveSecureToken({ apikey, data })
                                        .then((response) => {
                                            if (response.success) {
                                                localStorage.setItem(ls.H5LIVE_HASH_SECURE, JSON.stringify(response.data.data.token));
                                            }
                                        }).catch((error) => {
                                            if (!error.success) {
                                                setError(error);
                                                setShowError(true);
                                            }
                                        });
                                }
                                successfulLogin();
                            }
                        }).catch((error) => {
                            if (!error.success) {
                                setError(error);
                                setShowError(true);
                            }
                        });
                }
            }).catch((error) => {
                if (!error.success) {
                    setError(error);
                    setShowError(true);
                }
            })
    }

    const handleForgotPassword = () => {
        setForgotPassword(!forgotPassword);
        setTextfields({
            ...textfields,
            password: {
                ...textfields.password,
                show: !textfields.password.show
            }
        })
    }

    return (
        <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
        >
            <SnackbarMessage
                open={showError}
                close={handleCloseError}
                type={error.type ? error.type : "error"}
            >
                <b>{error.type ? "" : "Error:"} {error.code}</b> {error.message}
            </SnackbarMessage>
            <Grid item container xs={12}>
                <Grid item xs={12}>
                    <form autoComplete>
                        {
                            Object.values(textfields).map((textfield, i) => (
                                <TextField
                                    key={`${textfield.id}-${i}-signin`}
                                    focused
                                    fullWidth
                                    sx={{ minBlockSize: 2 }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {textfield.icon}
                                            </InputAdornment>
                                        )
                                    }}
                                    variant="standard"
                                    type={textfield.type}
                                    label={textfield.label}
                                    autoComplete={textfield.autoComplete}
                                    error={textfields[textfield.id].error}
                                    onKeyPress={handleAnyKeyPressed}
                                    onChange={handleUpdateTextfield(Object.keys(textfields)[i])}
                                    onSubmit={handleSubmitTextfield}
                                />
                            ))
                        }
                    </form>
                </Grid>
                <Grid item xs={12} mt={1}>
                    <Stack direction="row" justifyContent={"space-between"} alignItems='center'>
                        <FormControlLabel
                            className={classes.checkboxLabel}
                            control={<Checkbox sx={{ color: '#fff' }} color="primary" name="acceptTerms" />}
                            label={
                                <Typography variant="overline" color="white">
                                    Accept <Link underline="hover" href={link.IMPRINT} target="_blank">Terms and Conditions</Link>
                                </Typography>
                            }
                            checked={checkedTerms}
                            onChange={handleAcceptTerms}
                        />
                        <Typography variant="overline" color="white" onClick={handleForgotPassword}>
                            {forgotPassword ? "Remember your password?" : "Forgot password?"}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent={"space-between"}>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            onClick={handleLogin}
                            endIcon={<ArrowRightAlt />}
                        >
                            {forgotPassword ? "Reset" : "Login"}
                        </Button>
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={props.signup}
                        >
                            Sign Up
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </Grid>
    )
}
