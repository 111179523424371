import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment';

import * as ls from '../../utils/helper/ls-vars';
import * as bintu from '../../utils/bintu/api-requests';

import { makeStyles } from '@mui/styles';
import { Grid, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Hidden } from '@mui/material';
import { Delete, DeleteOutline, PersonAddAlt1Outlined } from '@mui/icons-material';

import SectionHeader from '../global/SectionHeader';
import SectionContainer from '../global/SectionContainer';
import SnackbarMessage from '../global/SnackbarMessage';
import ContentDialog from '../global/ContentDialog';
import InviteNewUser from '../organisation/InviteNewUser';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    inviteContainer: {
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(1.5),
        borderRadius: theme.spacing(0.5),
        color: theme.palette.common.white,
        marginBottom: theme.spacing(1)
    },
    textfieldWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    textfield: {
        width: 600,
        maWidth: '100%'
    },
    header: {
        fontWeight: '700 !important'
    },
    deleteContainer: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
        padding: theme.spacing(1),
        borderRadius: theme.spacing(0.5),
    },
    button: {
        marginRight: `${theme.spacing(1)} !important`,
        marginTop: `${theme.spacing(1)} !important`,
    }
}));

export default function User(props) {
    const classes = useStyles();
    const apikey = localStorage.getItem(ls.BINTU_APIKEY);
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});
    const [update, setUpdate] = useState(false);
    const [user, setUser] = useState([]);
    const [deleteId, setDeleteId] = useState("");

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowError(false);
    }

    const handleCreateNewToken = () => {
        setUpdate(!update);
    }

    const getUserList = () => {
        bintu.getUserList()
            .then((response) => {
                if (response.success) {
                    setUser(response.data);
                }
            }).catch((error) => {
                if (!error.success) {
                    setError(error);
                    setShowError(true);
                }
            });
    }

    const handleDeleteUser = (id) => () => {
        if (deleteId === id) setDeleteId("");
        else setDeleteId(id);
    }

    const handleSubmitDeletation = (toDelete) => () => {
        if (!toDelete) setDeleteId("")
        if (toDelete) {
            let data = {
                userId: toDelete
            }
            bintu.deleteUser(data)
                .then((response) => {
                    if (response.success) {
                        getUserList();
                    }
                }).catch((error) => {
                    if (!error.success) {
                        setError(error);
                        setShowError(true);
                    }
                })
        }
    }

    useEffect(() => {
        getUserList();
    }, [])

    return (
        <SectionContainer>
            <SnackbarMessage
                open={showError}
                close={handleCloseError}
                type={"error"}
            >
                <b>Error: {error.code}</b> {error.message}
            </SnackbarMessage>
            <Grid container>
                <Grid item xs={12} mb={1}>
                    <SectionHeader
                        title="User Overview"
                        interact
                        icon={<PersonAddAlt1Outlined />}
                        clicked={handleCreateNewToken}
                        button={"Invite new user"}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InviteNewUser
                        open={update}
                        handleClose={handleCreateNewToken}
                    />
                </Grid>
                <Grid item xs={12} sx={{ maxHeight: 350, overflow: 'scroll' }}>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography className={classes.header} variant="body2" color="primary">
                                            User ({user.length})
                                        </Typography>
                                    </TableCell>
                                    <Hidden mdDown>
                                        <TableCell>
                                            <Typography className={classes.header} variant="body2" color="primary">
                                                Created at
                                            </Typography>
                                        </TableCell>
                                        <TableCell />
                                    </Hidden>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    user.map((u, i) => (
                                        <Fragment key={i}>
                                            <ContentDialog
                                                open={deleteId === u.id}
                                                close={handleSubmitDeletation(false)}
                                                submit={handleSubmitDeletation(u.id)}
                                                submitButton={"Delete"}
                                                title={`You are about to delete ${u.email} from your organization!`}
                                                content={`Please confirm that you want to delete ${u.email} from your organization by clicking on "Delete".`}
                                            />
                                            <TableRow hover>
                                                <Hidden mdUp>
                                                    <TableCell>
                                                        <div className={classes.textfieldWrapper}>
                                                            <Typography variant="body2">
                                                                {u.email}
                                                            </Typography>
                                                            {
                                                                user.length === 1
                                                                    ? null
                                                                    :
                                                                    <IconButton size="small" onClick={handleDeleteUser(u.id)}>
                                                                        <DeleteOutline fontSize="inherit" />
                                                                    </IconButton>
                                                            }
                                                        </div>
                                                        <Typography variant="subtitle2" color="textSecondary">
                                                            Created: {moment(u.created_at).utc().format('MM/DD/YYYY hh:mm a')} (UTC)
                                                        </Typography>

                                                    </TableCell>
                                                </Hidden>
                                                <Hidden mdDown>
                                                    <TableCell>
                                                        <Typography variant="body2">
                                                            {u.email}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="subtitle2" color="textSecondary">
                                                            {moment(u.created_at).utc().format('MM/DD/YYYY hh:mm a')} (UTC)
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {
                                                            user.length === 1
                                                                ? null
                                                                :
                                                                <IconButton size="small" onClick={handleDeleteUser(u.id)}>
                                                                    <DeleteOutline fontSize="inherit" />
                                                                </IconButton>
                                                        }
                                                    </TableCell>
                                                </Hidden>
                                            </TableRow>
                                        </Fragment>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </SectionContainer>

    )
}
