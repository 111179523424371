import React, { Fragment, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import * as link from '../utils/helper/link-config';
import * as ls from '../utils/helper/ls-vars';
import * as bintu from '../utils/bintu/api-requests';

import notfound_light from '../assets/StreamNotFound_light.png';
import notfound_dark from '../assets/StreamNotFound_dark.png';

import { makeStyles } from '@mui/styles';
import { useTheme } from '@emotion/react';
import { Grid, Typography, Button, Collapse } from '@mui/material';
import { AddCircleOutline, HeadsetMicOutlined, List } from '@mui/icons-material';

import SectionHeader from '../components/global/SectionHeader';
import Loading from '../components/global/Loading';
import NewStreamOverview from '../components/stream/overview/NewStreamOverview';
import SnackbarMessage from '../components/global/SnackbarMessage';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    image: {
        width: 600,
        maxWidth: '100%',
        margin: '0 auto',
        display: 'block',
        marginBottom: theme.spacing(2)
    },
    item: {
        textAlign: 'center',
    },
    button: {
        margin: `${theme.spacing(1, 1, 0, 0)} !important`
    }
}));

export default function NewStream(props) {
    const classes = useStyles();
    const theme = useTheme();
    const navigate = useNavigate();
    let { id } = useParams();
    const orga = JSON.parse(localStorage.getItem(ls.BINTU_ORGA));
    const h5liveToken = JSON.parse(localStorage.getItem(ls.H5LIVE_HASH_SECURE));

    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(true);
    const [stream, setStream] = useState(false);

    document.title = "New Stream | nanoStream Cloud Dashboard";

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowError(false);
    }

    const redirect = (link) => () => {
        navigate(link);
    }

    const getStreams = () => {
        let data = {
            streamid: id
        }
        bintu.getStreamById(data)
            .then((response) => {
                if (response.success) {
                    setStream(response.data)
                    setLoading(false)
                }
            }).catch((error) => {
                setStream(false);
                setError(error);
                setLoading(false);
            })
    }

    useEffect(() => {
        // TO DO FETCH STREAM
        if (!stream) {
            getStreams();
        }
    }, [])

    return (
        <Grid container className={classes.root}>
            <SnackbarMessage
                open={showError}
                close={handleCloseError}
                type={"error"}
            >
                <b>Error: {error.code}</b> {error.message}
            </SnackbarMessage>
            {
                loading
                    ? <Loading />
                    :
                    <Fragment>
                        {
                            stream
                                ?
                                <Grid item xs={12}>
                                    <SectionHeader
                                        title="Easy instructions to your live stream"
                                        underline="Copy the ingest url and stream name into your live encoder software or hardware and start a broadcast."
                                        button="See Stream Overview"
                                        interact
                                        icon={<List />}
                                        clicked={redirect(`${link.STREAM}/${id}`)}
                                    />
                                    <Collapse in={stream}>
                                        <NewStreamOverview
                                            stream={stream}
                                            token={h5liveToken}
                                            secure={orga.secure}
                                        />
                                    </Collapse>
                                </Grid>
                                :
                                <Grid item xs={12} className={classes.item}>
                                    <img className={classes.image} src={theme.palette.mode === 'light' ? notfound_light : notfound_dark} />
                                    <Typography variant="h5" color="primary" >
                                        Oops, we could not find the stream you are looking for. <br />
                                    </Typography>
                                    <Typography variant="body1" color="textSecondary">
                                        If you think this was a mistake, contact our support.
                                    </Typography>
                                    <div className={classes.buttonWrapper}>
                                        <Button
                                            className={classes.button}
                                            variant="contained"
                                            color="primary"
                                            onClick={redirect(link.CREATE_STREAM)}
                                            startIcon={<AddCircleOutline />}
                                        >
                                            Create new stream
                                        </Button>
                                        <Button
                                            className={classes.button}
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => window.open(link.CONTACT, "_blank")}
                                            startIcon={<HeadsetMicOutlined />}
                                        >
                                            Talk to our support
                                        </Button>
                                    </div>
                                </Grid>
                        }
                    </Fragment>
            }
        </Grid>

    )
}
