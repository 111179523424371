import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import * as link from '../utils/helper/link-config';
import * as ls from '../utils/helper/ls-vars';
import * as bintu from '../utils/bintu/api-requests';
import * as m from '../utils/helper/methods';

import notfound_light from '../assets/space_light.png';
import notfound_dark from '../assets/space_dark.png';

import { makeStyles, useTheme } from '@mui/styles';
import { Button, Grid, Typography } from '@mui/material';
import { AddCircleOutline, HeadsetMicOutlined } from '@mui/icons-material';

import StreamList from '../components/streams/List';
import ScrollToTop from '../components/global/ScrollToTop';
import Filter from '../components/streams/Filter';
import Loading from '../components/global/Loading';
import SnackbarMessage from '../components/global/SnackbarMessage';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    image: {
        width: 600,
        maxWidth: '100%',
        margin: '0 auto',
        display: 'block',
        marginBottom: theme.spacing(2)
    },
    item: {
        textAlign: 'center',
    },
    button: {
        margin: `${theme.spacing(1, 1, 0, 0)} !important`
    }
}));

export default function AllStreams(props) {
    const classes = useStyles();
    const theme = useTheme();
    const navigate = useNavigate();
    const anchorId = "all-streams-top-anchor";

    const orga = JSON.parse(localStorage.getItem(ls.BINTU_ORGA));
    const createdAt = orga?.created_at;

    const [searchParams] = useSearchParams();
    let validStates = ["all", "live", "ended", "created", "deleted", "locked"];

    const [loading, setLoading] = useState(true);
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});
    const [streams, setStreams] = useState(false);
    const [streamCount, setStreamCount] = useState(0);
    const [streamgroup, setStreamgroup] = useState(searchParams.get("streamgroup") ? JSON.parse(searchParams.get("streamgroup")) : true);
    const [filter, setFilter] = useState({
        from: createdAt,
        until: new Date(Date.now()).toISOString(),
        tags: [],
        offset: 0,
        quantity: 25,
        state: searchParams.get("state") ? validStates.some(s => { return searchParams.get("state").indexOf(s) !== -1 }) ? searchParams.get("state") : "all" : "all"
    });

    document.title = "Streams | nanoStream Cloud Dashboard";

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowError(false);
    }

    const handleUpdatePagination = (tableCount) => {
        setFilter({
            ...filter,
            quantity: tableCount.rowsPerPage,
            offset: tableCount.page * tableCount.rowsPerPage
        })
    }

    const handleUpdateFilter = (filter) => {
        setFilter(filter);
    }

    const getSingleStreamList = (streamFilter, data) => {
        bintu.getSingleStreamCollection(data)
            .then((response) => {
                if (response.success) {
                    setStreams(response.data)
                    setLoading(false);

                    bintu.getStreamCount(data)
                        .then((success) => {
                            if (success.success) {
                                setStreamCount(success.data)
                            }
                        }).catch((error) => {
                        })
                }
            }).catch((error) => {
                setStreams(false);
                setLoading(false);
                if (!error.success) {
                    setError(error);
                    setShowError(true);
                }
            })
    }

    const getStreamGroupList = (streamFilter, data) => {
        bintu.getStreamCollection(data)
            .then((response) => {
                if (response.success) {
                    setStreams(response.data)
                    setLoading(false);

                    bintu.getStreamGroupCount(data)
                        .then((success) => {
                            if (success.success) {
                                setStreamCount(success.data)
                            }
                        }).catch((error) => {
                        })
                }
            }).catch((error) => {
                setStreams(false);
                setLoading(false);
                if (!error.success) {
                    setError(error);
                    setShowError(true);
                }
            })
    }

    const handleFetchStreamgroup = () => {
        setStreamgroup(!streamgroup);
    }

    const updateQueries = (newQuery) => {
        let query = m.GENERATE_SEARCH_QUERY(newQuery);
        window.history.pushState({}, "", `?${query}`);
    }

    useEffect(() => {
        let streamFilter = { ...filter, state: filter.state === "all" ? "" : filter.state };
        let data = { filter: streamFilter };

        updateQueries({ state: streamFilter.state, streamgroup });

        streamgroup ? getStreamGroupList(streamFilter, data) : getSingleStreamList(streamFilter, data);
    }, [streamgroup, filter])


    return (
        <Grid container className={classes.root} id={anchorId}>
            <SnackbarMessage
                open={showError}
                close={handleCloseError}
                type={"error"}
            >
                <b>Error: {error.code}</b> {error.message}
            </SnackbarMessage>

            {
                loading
                    ? <Loading />
                    :
                    <Fragment>
                        {
                            streams
                                ?
                                <Grid item xs={12}>
                                    <Grid item xs={12} mb={1}>
                                        <Filter
                                            filter={filter}
                                            updated={handleUpdateFilter}
                                            fetchStreamgroup={streamgroup}
                                            handleFetchStreamgroup={handleFetchStreamgroup}
                                        />
                                    </Grid>
                                    <StreamList
                                        isStreamgroup={streamgroup}
                                        count={streamCount}
                                        streams={streams}
                                        updatePagination={handleUpdatePagination}
                                    />
                                </Grid>
                                :
                                <Grid item xs={12} className={classes.item}>
                                    <img className={classes.image} src={theme.palette.mode === 'light' ? notfound_light : notfound_dark} />
                                    <Typography variant="h5" color="primary" >
                                        No streams found! <br />
                                    </Typography>
                                    <Typography variant="body1" color="textSecondary">
                                        If you think this was a mistake, contact our support.
                                    </Typography>
                                    <div className={classes.buttonWrapper}>
                                        <Button
                                            className={classes.button}
                                            variant="contained"
                                            color="primary"
                                            onClick={() => navigate(link.CREATE_STREAM)}
                                            startIcon={<AddCircleOutline />}
                                        >
                                            Create a new stream
                                        </Button>
                                        <Button
                                            className={classes.button}
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => window.open(link.CONTACT, "_blank")}
                                            startIcon={<HeadsetMicOutlined />}
                                        >
                                            Talk to our support
                                        </Button>
                                    </div>
                                </Grid>
                        }
                    </Fragment>
            }
            <ScrollToTop anchorId={anchorId} />
        </Grid >
    )
}
