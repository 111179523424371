import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import * as ls from '../../utils/helper/ls-vars';
import * as m from '../../utils/helper/methods';
import * as link from '../../utils/helper/link-config';
import * as bintu from '../../utils/bintu/api-requests';
import * as alerts from '../../analytics/utils/analytics-helper';
import * as metrics from '../../analytics/utils/api-requests';

import { makeStyles, useTheme } from '@mui/styles';
import { Box, Button, Divider, Grid, Icon, Skeleton, Tooltip, Typography, alpha } from '@mui/material';
import { AddToQueueOutlined, AdminPanelSettingsOutlined, Block, BlockOutlined, DeleteSweepOutlined, LiveTvOutlined, OpenInNew, RunningWithErrorsOutlined, StopCircleOutlined, UpcomingOutlined, VideoLibraryOutlined } from '@mui/icons-material';

import Loading from '../global/Loading';
import SectionContainer from '../global/SectionContainer';
import SnackbarMessage from '../global/SnackbarMessage';
import State from '../global/State';
import SectionHeader from '../global/SectionHeader';


const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        textAlign: 'center'
    },
    headerSection: {
        marginBottom: `${theme.spacing(1)} !important`
    },
    item: {
        '&:hover': {
            transform: 'scale(1.02)',
        },
    },
}));

export default function StreamCountOverview(props) {
    const classes = useStyles();
    const theme = useTheme();
    const navigate = useNavigate();

    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(true);
    const [count, setCount] = useState({ all: 0, live: 0, created: 0, ended: 0, deleted: 0, locked: 0, lockedByAdmin: 0 });
    const [alertStats, setAlertStats] = useState(false);
    const [timestamp, setTimestamp] = useState(moment());
    const [allGood, setAllGood] = useState(true);
    const [icons] = useState({
        all: <VideoLibraryOutlined />,
        live: <LiveTvOutlined />,
        created: <AddToQueueOutlined />,
        ended: <StopCircleOutlined />,
        deleted: <DeleteSweepOutlined />,
        locked: <BlockOutlined />,
        lockedByAdmin: <AdminPanelSettingsOutlined />
    })

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowError(false);
    }

    const countStates = () => {
        bintu.countStates()
            .then((response) => {
                if (response.success) {
                    setCount(response.data);
                    setLoading(false);
                    if (props.getCreated) props.updateAmountOfStreams(response.data.all)
                }
            }).catch((error) => {
                setCount(false);
                setLoading(false);
                if (!error.success) {
                    setError(error);
                    setShowError(true);
                }
            })
    }

    const getAlerts = () => {
        metrics.getIngestAlertsAndAdvices()
            .then((response) => {
                if (response.success) {
                    let fetchedStats = response.data.statistic;
                    setTimestamp(response.data.timestamp)
                    setAlertStats(fetchedStats);
                    setAllGood(fetchedStats.alerts === 0);
                }
            }).catch((error) => {
                if (!error.success) {
                    setError(error);
                    setShowError(true);
                }
            })
    }

    const openInNew = (link) => () => {
        window.open(link, "_blank");
    }

    const handleOpenStreams = (state) => () => {
        navigate(`${link.STREAMS}${state === "all" ? "" : `?state=${state}`}`)
    }

    const handleRedirect = (link) => () => {
        navigate(link)
    }

    useEffect(() => {
        if (loading) {
            countStates();
            getAlerts();
        }
    }, [])

    return (
        <Fragment>
            <SnackbarMessage
                open={showError}
                close={handleCloseError}
                type={"error"}
            >
                <b>Error: {error.code}</b> {error.message}
            </SnackbarMessage>
            <Grid container>
                <Grid item xs={12} mt={2}>
                    <Box
                        sx={{
                            p: 2,
                            display: 'inline-block',
                            cursor: 'pointer',
                            m: 0.5,
                            bgcolor: theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.grey[900],
                            // boxShadow: theme.shadows[5],
                            backgroundColor: theme.palette.common.white,
                            backgroundImage: `linear-gradient(to bottom, ${alpha(theme.palette.primary.main, 0.9)}, ${alpha(theme.palette.primary.main, 1.0)})`,
                            borderRadius: theme.spacing(1.5)
                        }}
                        className={classes.item}
                        onClick={handleRedirect(link.ALERTS)}
                    >
                        <Icon sx={{ color: theme.palette.common.white }}>
                            <UpcomingOutlined />
                        </Icon>
                        <Typography color="white" variant="button" sx={{ display: 'block', lineHeight: 1, mt: 1, fontWeight: 700 }}>
                            Alerts
                        </Typography>
                        <Typography variant="h6" color="white" sx={{ display: 'block', lineHeight: 1, mt: 0.5, fontWeight: 700 }}>
                            {alertStats ? alertStats?.streamsWithAlerts : <Skeleton />}
                        </Typography>
                    </Box>
                    {

                        Object
                            .values(count)
                            .map((amount, index) => (
                                <Box
                                    sx={{
                                        p: 2,
                                        display: 'inline-block',
                                        cursor: 'pointer',
                                        m: 0.5,
                                        bgcolor: theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.grey[900],
                                        // boxShadow: theme.shadows[5],
                                        borderRadius: theme.spacing(1.5)
                                    }}
                                    className={classes.item}
                                    onClick={handleOpenStreams(Object.keys(count)[index])}
                                >
                                    <Icon sx={{ color: theme.palette.text.secondary }}>
                                        {icons[Object.keys(count)[index]]}
                                    </Icon>
                                    <Typography color="textSecondary" variant="button" sx={{ display: 'block', lineHeight: 1, mt: 1 }}>
                                        {Object.keys(count)[index]}
                                    </Typography>
                                    <Typography variant="h6" color="primary" sx={{ display: 'block', lineHeight: 1, mt: 0.5, fontWeight: 700 }}>
                                        {loading ? <Skeleton /> : amount}
                                    </Typography>
                                </Box>
                            ))
                    }
                </Grid>
                {
                    alertStats
                    &&
                    <Grid item xs={12}>
                        <SectionContainer
                            small
                            title="Health Status of your Live Streams"
                            underline={<>
                                <span style={{ color: alerts.ALERT_COLOR_SCHEME.advices.color }}>●</span> {`Advices: ${alertStats?.advices} | `}
                                <span style={{ color: alerts.ALERT_COLOR_SCHEME.minorAlerts.color }}>●</span> {`Minor: ${alertStats?.minorAlerts} | `}
                                <span style={{ color: alerts.ALERT_COLOR_SCHEME.moderateAlerts.color }}>●</span> {`Moderate: ${alertStats?.moderateAlerts} | `}
                                <span style={{ color: alerts.ALERT_COLOR_SCHEME.criticalAlerts.color }}>●</span> {`Critical: ${alertStats?.criticalAlerts}`}
                            </>}
                        >
                            <Grid container alignItems={'center'}>
                                <Grid item xs={12} my={1.5}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    {/* <SectionHeader
                                            title={`Live Streams: ${alertStats?.streamsOnline} (${alertStats?.alerts} Alerts)`}
                                            underline={`Advices: ${alertStats?.advices} | Minor: ${alertStats?.minorAlerts} | Moderate: ${alertStats?.moderateAlerts} | Critical: ${alertStats?.criticalAlerts} `}
                                        /> */}
                                    <SectionHeader
                                        title={`Live Streams: ${alertStats?.streamsOnline} (${alertStats?.alerts} Alerts)`}
                                        interact
                                        button="How to use"
                                        icon={<OpenInNew />}
                                        clicked={openInNew(link.ALERTS_HOW_TO_USE)}
                                        underline={`This analysis is based on the last 15 minutes. Data retrieved: ${moment.utc(moment.unix(timestamp)).format(`MM/DD/YYYY hh:mm a`)} (UTC)`}
                                    />
                                </Grid>
                                <Grid item xs={12} my={1.5}>
                                    {
                                        allGood
                                            ?
                                            <Fragment>
                                                <div
                                                    style={{
                                                        backgroundColor: theme.palette.success.main,
                                                        width: `100%`,
                                                        minHeight: '20px',
                                                        borderTopLeftRadius: '10px',
                                                        borderBottomLeftRadius: '10px',
                                                        borderTopRightRadius: '10px',
                                                        borderBottomRightRadius: '10px'
                                                    }}
                                                />
                                            </Fragment>
                                            :
                                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
                                                {
                                                    Object.keys(alerts.ALERT_COLOR_SCHEME).map((alert, index, keyWords) => {
                                                        return (
                                                            alertStats[alert] > 0
                                                            &&
                                                            <Tooltip key={index} title={`Streams: ${alertStats?.streamsOnline} / ${alerts.ALERT_COLOR_SCHEME[alert].title}: ${alertStats[alert]}`}>
                                                                <div
                                                                    style={{
                                                                        backgroundColor: alerts.ALERT_COLOR_SCHEME[alert].color,
                                                                        margin: '5px',
                                                                        maxWidth: `${(alertStats[alert] / alertStats.streamsWithNotifications) * 100}%`,
                                                                        width: '100%', // To ensure it takes up all available space up to the maxWidth
                                                                        minHeight: '20px',
                                                                        borderRadius: '10px',
                                                                    }}
                                                                />
                                                            </Tooltip>)
                                                    }
                                                    )
                                                }
                                            </div>
                                    }
                                </Grid>

                            </Grid>
                            {
                                props.showAlertsRedirect
                                &&
                                <Button
                                    sx={{ mt: 1 }}
                                    size="small"
                                    variant="contained"
                                    endIcon={<UpcomingOutlined />}
                                    onClick={handleRedirect(link.ALERTS)}
                                >
                                    See all alerts
                                </Button>
                            }
                        </SectionContainer>
                    </Grid>
                }
            </Grid>
        </Fragment>
    )
}


{/* <Grid item xs={12} sm={2} md={"auto"}>
<Box sx={{ position: 'relative', display: 'inline-flex' }}>
    <CircularProgress
        color="success"
        variant="determinate"
        sx={{ position: 'relative' }}
        value={(alertStats.streamsOnline / alertStats.streamsOnline) * 100}
        thickness={20}
        size={70}
    />
    <CircularProgress
        variant="determinate"
        sx={{ position: 'absolute', color: alerts.ALERT_COLOR_SCHEME.minorAlerts.color }}
        value={(alertStats.minorAlerts / alertStats.streamsOnline) * 100}
        thickness={10}
        size={70}
    />
    <CircularProgress
        variant="determinate"
        sx={{ position: 'absolute', color: alerts.ALERT_COLOR_SCHEME.moderateAlerts.color }}
        value={((alertStats.minorAlerts + alertStats.moderateAlerts) / alertStats.streamsOnline) * 100}
        thickness={10}
        size={70}
    />
    <CircularProgress
        variant="determinate"
        sx={{ position: 'absolute', color: alerts.ALERT_COLOR_SCHEME.criticalAlerts.color }}
        value={((alertStats.minorAlerts + alertStats.moderateAlerts + alertStats.criticalAlerts) / alertStats.streamsOnline) * 100}
        thickness={10}
        size={70}
    />
</Box>
</Grid>
<Grid item xs={12} sm={10} md={"auto"} sx={{ pl: { sm: 2 } }}>
<SectionHeader
    title={`Live Streams: ${alertStats?.streamsOnline} (${alertStats?.alerts} Alerts)`}
    underline={`Minor: ${alertStats?.minorAlerts} | Moderate: ${alertStats?.moderateAlerts} | Critical: ${alertStats?.criticalAlerts} `}
/>
</Grid> */}
{/* {
                        alertStats
                        &&
                        <Grid item xs={12} mt={1}>
                            <SectionHeader
                                small
                                // title={`Alerts: ${alertStats?.alerts}`}
                                underline={`Live Streams: ${alertStats?.streamsOnline} (${alertStats?.alerts} Alerts) | Minor: ${alertStats?.minorAlerts} | Moderate: ${alertStats?.moderateAlerts} | Critical: ${alertStats?.criticalAlerts} `}
                            // interact
                            // button="See all Alerts"
                            // icon={<RunningWithErrorsOutlined />}
                            // clicked={handleRedirect(link.ALERTS)}
                            />
                            <div style={{ display: 'flex', width: '100%', marginTop: '5px' }}>
                                {
                                    allGood
                                        ?
                                        <div
                                            style={{
                                                backgroundColor: alerts.ALERT_COLOR_SCHEME.advices.color,
                                                width: `100%`,
                                                minHeight: '20px',
                                                borderTopLeftRadius: '10px',
                                                borderBottomLeftRadius: '10px',
                                                borderTopRightRadius: '10px',
                                                borderBottomRightRadius: '10px'
                                            }}
                                        />
                                        :
                                        <Fragment>
                                            {
                                                Object.keys(alerts.ALERT_COLOR_SCHEME).map((alert, index, keyWords) => {
                                                    const nonZeroAlerts = Object.keys(alerts.ALERT_COLOR_SCHEME).filter(alert => alertStats[alert] > 0);
                                                    const firstNonZeroAlert = nonZeroAlerts[0];
                                                    const lastNonZeroAlert = nonZeroAlerts[nonZeroAlerts.length - 1];

                                                    return (
                                                        alertStats[alert] > 0
                                                        &&
                                                        <Tooltip key={index} title={`${alerts.ALERT_COLOR_SCHEME[alert].title}: ${alertStats[alert]}`}>
                                                            <div
                                                                style={{
                                                                    backgroundColor: alerts.ALERT_COLOR_SCHEME[alert].color,
                                                                    // backgroundImage: `linear-gradient(to right,
                                                                    //     ${alpha(alerts.ALERT_COLOR_SCHEME[alert].color, 0.6)}, 
                                                                    //     ${alpha(index < keyWords.length - 1
                                                                    //     ? alerts.ALERT_COLOR_SCHEME[keyWords[index + 1]].color
                                                                    //     : alerts.ALERT_COLOR_SCHEME[alert].color, 1.0)})`,
                                                                    width: `${(alertStats[alert] / alertStats.alerts) * 100}%`,
                                                                    minHeight: '20px',
                                                                    borderRight: (alert !== lastNonZeroAlert) && `${theme.spacing(0.5)} solid ${theme.palette.common.white}`,
                                                                    borderTopLeftRadius: alert === firstNonZeroAlert && '10px',
                                                                    borderBottomLeftRadius: alert === firstNonZeroAlert && '10px',
                                                                    borderTopRightRadius: alert === lastNonZeroAlert && '10px',
                                                                    borderBottomRightRadius: alert === lastNonZeroAlert && '10px'
                                                                }}
                                                            />
                                                        </Tooltip>)
                                                }
                                                )
                                            }
                                        </Fragment>

                                }
                            </div>
                        </Grid>
                    } */}

// {
//     loading
//         ?
//         <Loading />
//         :
//         <Fragment>
//             {
//                 count
//                 &&
//                 <Fragment>
//                     {
//                         Object.values(count).map((amount, index) => (
//                             <Grid
//                                 item
//                                 key={index}
//                                 xs={6} sm={"auto"}
//                                 sx={{ pr: 2, minWidth: { sm: 200 }, cursor: 'pointer' }}
//                                 className={classes.item}
//                                 onClick={handleOpenStreams(Object.keys(count)[index])}
//                             >
//                                 <SectionContainer>
//                                     <State state={Object.keys(count)[index]} />
//                                     {/* <Typography variant="button" color="textSecondary">
//                                          {icons[Object.keys(count)[index]]}   Streams
//                                     </Typography> */}
//                                     <Typography variant="h2" sx={{ fontWeight: 700 }} color="primary">
//                                         {amount}
//                                     </Typography>
//                                 </SectionContainer>
//                             </Grid>
//                         ))
//                     }
//                 </Fragment>
//             }
//         </Fragment>
// }

// {
//     <Grid container>
//     <Grid item xs={12}>
//         <Collapse in={!loading}>
//             <SectionContainer>
//                 <Grid container className={classes.root} alignItems="row">
//                     {
//                         count
//                         &&
//                         <Fragment>
//                             {
//                                 Object.values(count).map((amount, index) => (
//                                     <Fragment>
//                                         <Grid
//                                             item
//                                             key={index}
//                                             xs={3} md={"auto"}
//                                             sx={{ px: 2, py: { xs: 0.5, md: 0 }, cursor: 'pointer', textAlign: 'left' }}
//                                             className={classes.item}
//                                             onClick={handleOpenStreams(Object.keys(count)[index])}
//                                         >
//                                             {/* <State state={Object.keys(count)[index]} /> */}
//                                             {/* <Box
//                                         sx={{
//                                             px: 1, py: 0.5,
//                                             display: 'inline-block',
//                                             backgroundColor: theme.palette.primary.main,
//                                             borderRadius: theme.spacing(0.5)
//                                         }}
//                                     > */}
//                                             <Icon sx={{ color: theme.palette.text.secondary }}>
//                                                 {icons[Object.keys(count)[index]]}
//                                             </Icon>
//                                             {/* </Box> */}
//                                             <Typography
//                                                 sx={{
//                                                     display: 'block', textTransform: "capitalize", lineHeight: 1,
//                                                     typography: { xs: "overline", sm: "button" }
//                                                 }}
//                                                 color="textSecondary"
//                                             >
//                                                 {Object.keys(count)[index]} Streams
//                                             </Typography>
//                                             <Typography sx={{ typography: { xs: "h5", md: "h5" }, fontWeight: 700 }} color="primary">
//                                                 {amount}
//                                             </Typography>
//                                             <Divider sx={{ mt: 1, display: { xs: "block", md: "none" } }} />
//                                         </Grid>
//                                         <Divider orientation="vertical" flexItem sx={{ display: { xs: "none", md: "block" } }} />
//                                     </Fragment>
//                                 ))
//                             }
//                         </Fragment>
//                     }
//                 </Grid>
//             </SectionContainer>
//         </Collapse>
//     </Grid>
//     <Grid item xs={12} sm={6}>
//         <SectionContainer
//             orange
//             title="Alerts"
//             underline="Your alerts give you valuable insights"
//         >
//         </SectionContainer>
//     </Grid>
// </Grid>
// }