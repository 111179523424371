import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import * as webrtcHelper from '../../utils/helper/webcaster';
import * as link from '../../utils/helper/link-config';

import { useTheme } from '@mui/styles';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, IconButton, Tooltip, Typography, alpha } from '@mui/material';
import { ArrowForwardIos, MicNoneOutlined, MicOffOutlined, OndemandVideoOutlined, SettingsOutlined, VideocamOffOutlined, VideocamOutlined } from '@mui/icons-material';
import { green, red, yellow } from '@mui/material/colors';

import SnackbarMessage from '../global/SnackbarMessage';
import SectionContainer from '../global/SectionContainer';
import Loading from '../global/Loading';
import ContentDialog from '../global/ContentDialog';
import WebcasterSettings from './WebcasterSettings';
import ContentTable from '../global/ContentTable';

export default function WebcasterVideo(props) {
    const theme = useTheme();
    const webcasterId = "webcaster-preview";
    let { id } = useParams();
    let finn = useRef();

    const { Webcaster, HelperUtils, DeviceUtils, VERSION } = window.WebcasterApiV6;
    const [client, setClient] = useState(null);
    const [videoElement, setVideoElement] = useState(null);

    const [streaming, setStreaming] = useState(false);
    const [initializing, setInitializing] = useState(false);
    const [enableStartStreaming, setEnableStartStreaming] = useState(true);

    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});

    const [hasPermission, setHasPermission] = useState(null);

    const [triggerNewScreen, setTriggerNewScreen] = useState(false);
    const [triggerInit, setTriggerInit] = useState(true);
    const [useCamera, setUseCamera] = useState(true);
    const [devices, setDevices] = useState({});
    const [selectedDevice, setSelectedDevices] = useState({ audio: "", video: "" });
    const [constraints, setConstraints] = useState({ audio: true, video: true });
    const [maxBitrates, setMaxBitrates] = useState({ audio: 64, video: 1000 });
    const [resolution, setResolution] = useState(webrtcHelper.RESOLUTION[webrtcHelper.RESOLUTION.length - 2]);
    const [maxFramerate, setMaxFramerate] = useState(30);
    const [extraSettings, setExtraSettings] = useState({ echoCancellation: true, noiseSuppression: true, autoGainControl: true });

    const [openSettings, setOpenSettings] = useState(false);
    const [pendingSettings, setPendingSettings] = useState({ useCamera, extraSettings, maxBitrates, selectedDevice, resolution, maxFramerate });

    const [status, setStatus] = useState(null);
    const [quality, setQuality] = useState({ title: "good", color: green["A700"] });
    const [packetLoss, setPacketLoss] = useState(null);
    const [connectionRtt, setConnectionRtt] = useState(null);

    const handleQuickAction = (id) => () => setConstraints({ ...constraints, [id]: !constraints[id] });

    const handleCloseError = (event, reason) => {
        setShowError(false);
        if (reason === 'clickaway') return;
    }

    const getPermission = async () => {
        try {
            const stream = await navigator.mediaDevices?.getUserMedia(constraints);
            setHasPermission(true);
            getDevices();
        } catch (error) {
            setHasPermission(false);
            setError({ type: "error", code: "Permission denied:", message: 'Permission to access the camera and microphone was denied. Please grant permission and refresh the page.' });
            setShowError(true);
        }
    }

    const initStreaming = async () => {
        setInitializing(true);
        if (!streaming) initWebcaster();
        if (streaming) stopBroadcast();
    }

    // src: https://github.com/webrtc/samples/blob/gh-pages/src/content/devices/input-output/js/main.js
    const getDevices = async () => {
        try {
            const deviceInfos = await navigator.mediaDevices?.enumerateDevices();

            const sortedDevices = deviceInfos
                ?.map(info => info.kind)
                ?.filter((kind, index, self) => self.indexOf(kind) === index)
                ?.reduce((acc, kind) => {
                    acc[kind] = deviceInfos.filter(device => device.kind === kind);
                    return acc;
                }, {});
            setDevices(sortedDevices);
            setSelectedDevices({
                audio: sortedDevices.audioinput?.[0]?.deviceId,
                video: sortedDevices.videoinput?.[0]?.deviceId
            });
            console.log('Sorted fetched devices:', sortedDevices);

        } catch (error) {
            console.error("Error fetching devices: ", error);
            // setError({ type: "warning", message: `` });
            // setShowError(true);
        }
    }

    const createAudioTrack = async () => {
        let audioStream = await navigator.mediaDevices?.getUserMedia({
            audio: constraints.audio && {
                deviceId: selectedDevice.audio
                    ? { exact: selectedDevice.audio }
                    : undefined
            },
        });
        return audioStream.getAudioTracks()?.[0];
    };

    const createDisplayVideoTrack = async () => {
        let videoStream = await navigator.mediaDevices?.getDisplayMedia({ video: true });
        return videoStream.getVideoTracks()?.[0];
    };

    const createMediaStream = async () => {
        let videoTrack = await createDisplayVideoTrack();
        let audioTrack = await createAudioTrack();
        return new MediaStream([audioTrack, videoTrack]);
    };

    // src: https://github.com/webrtc/samples/blob/gh-pages/src/content/getusermedia/gum/js/main.js
    const initPreview = async () => {

        let streamConstraints;

        if (videoElement.srcObject) {
            const previousStream = videoElement.srcObject;
            previousStream.getTracks().forEach(track => track.stop());
            videoElement.srcObject = null;
            console.log("Deleting..")
        }

        if (useCamera) {
            streamConstraints = {
                audio: constraints.audio && { deviceId: selectedDevice.audio ? { exact: selectedDevice.audio } : undefined },
                video: constraints.video && {
                    deviceId: selectedDevice.video ? { exact: selectedDevice.video } : undefined,
                    frameRate: { ideal: maxFramerate },
                    width: { ideal: resolution.width },
                    height: { ideal: resolution.height }
                }
            };
        }

        try {
            const stream = useCamera
                ? await navigator.mediaDevices?.getUserMedia(streamConstraints)
                : await createMediaStream();

            if (videoElement) {
                const videoTracks = stream.getVideoTracks();
                const audioTracks = stream.getAudioTracks();
                finn.current = stream;
                videoElement.srcObject = finn.current;

                console.log('Got stream with constraints:', streamConstraints);
                console.log(`Using video device: ${constraints.video ? videoTracks[0].id : "Muted video device"}`);
                console.log(`Using audio device:  ${constraints.audio ? audioTracks[0].id : "Muted audio device"}`);
            }
        } catch (e) {
            console.error("Error getting media stream: ", e);
        }
    };

    // src: https://git.nanocosmos.de/webrtc/webcaster-api/-/blob/main/MIGRATION_GUIDE.md
    const initWebcaster = async () => {
        let stream = props.stream;

        let initConfig = {
            inputCfg: {
                mediaStream: videoElement?.srcObject,
                muted: {
                    video: !constraints.video,
                    audio: !constraints.audio
                },
                mediaStreamCfg: {
                    "audioConstraints": {
                        "autoGainControl": extraSettings.autoGainControl,
                        "channelCount": 2,
                        "echoCancellation": extraSettings.echoCancellation,
                        "noiseSuppression": extraSettings.noiseSuppression
                    }
                },
                "broadcastCfg": {
                    "transcodeAudioBitrateBps": HelperUtils.kbps(maxBitrates.audio),
                    "maxAudioBitrateBps": HelperUtils.kbps(maxBitrates.audio),
                    "maxVideoBitrateBps": HelperUtils.kbps(maxBitrates.video),
                    "maxEncodingFramerate": maxFramerate
                },
            },
            reconnect: true,
            ingestUrl: stream.ingest?.rtmp?.url,
            streamName: stream.ingest?.rtmp?.streamname,
            serverUrl: 'https://bintu-webrtc.nanocosmos.de/p/webrtc',
        };

        let webcasterClient = new Webcaster(initConfig);

        webcasterClient.onMetrics = (metrics) => {
            const rtcstats = metrics.rtcstats;
            const currConnectionRtt = rtcstats.connection.rtt.value;
            const currPacketLoss = rtcstats.connection.packetLoss.value;

            setConnectionRtt(currConnectionRtt);
            setPacketLoss(currPacketLoss);

            if (currConnectionRtt === undefined || currPacketLoss === undefined) {
                return // values can be undefined
            }

            // thresholds
            const packetLossLowerBound = 5;
            const packetLossUpperBound = 10;
            const rttLowerBound = 150;
            const rttUpperBound = 250;

            if (status === 'failed') return;

            if (currPacketLoss < packetLossLowerBound && currConnectionRtt < rttLowerBound) {
                setQuality({ title: "good", color: green["A700"] });
            }

            if ((currPacketLoss > packetLossLowerBound && currPacketLoss < packetLossUpperBound)
                || (currConnectionRtt > rttLowerBound && currConnectionRtt < rttUpperBound)) {
                setQuality({ title: "medium", color: yellow["A700"] });

            }

            if (currPacketLoss > packetLossUpperBound || currConnectionRtt > rttUpperBound) {
                setQuality({ title: "bad", color: red["A700"] });
            }
        };

        webcasterClient.onStateChange = state => {
            const status = webcasterClient.getUpstreamStatus();
            if (status.connectionState === 'failed') setQuality(null);
            setStatus(status.connectionState);
        }

        webcasterClient.setup()
            .then(async () => {
                console.log('Webcaster.setup done');
                setClient(webcasterClient);
            }).catch((err) => {
                console.error('Error setup webcaster:', err);
                setError({ type: "error", message: err.message });
                setShowError(true);
                setInitializing(false);
            });
    }

    const startBroadcast = async () => {
        try {
            await client.startBroadcast();
            console.log('Starting broadcast');
            setStreaming(true);
            setInitializing(false);
        } catch (err) {
            console.error('Error starting broadcast:', err);
            setError({ type: "error", message: err.message });
            setShowError(true);
            setInitializing(false);
        }
    }

    const stopBroadcast = async () => {
        try {
            await client.stopBroadcast();
            console.log('Stopping broadcast');
            setInitializing(false);
            setStreaming(false);
        } catch (err) {
            console.error('Error stopping broadcast:', err);
            setError({ type: "error", message: err.message });
            setShowError(true);
            setInitializing(false);
        }

    }
    const setMuted = async () => {
        try {
            await client.setMuted({
                audio: !constraints.audio,
                video: !constraints.video
            });
        } catch (err) {
            console.error('Error recovering client:', err);
            setError({ type: "error", message: err.message });
            setShowError(true);
        }
    }

    const dispose = async () => {
        try {
            await client.dispose();
            console.log('Disposed Client');
            setClient(null);
        } catch (err) {
            console.error('Error recovering client:', err);
            setError({ type: "error", message: err.message });
            setShowError(true);
        }
    }

    const handleSubmitChanges = async () => {

        if (client) await dispose();

        const mediaSourceChanged = useCamera === pendingSettings.useCamera;
        const deviceChanged = selectedDevice === pendingSettings.selectedDevice;
        const extraSettingsChanged = extraSettings === pendingSettings.extraSettings;
        const maxBitratesChanged = maxBitrates === pendingSettings.maxBitrates;
        const maxFramerateChanged = maxFramerate === pendingSettings.maxFramerate;
        const resolutionChanged = resolution === pendingSettings.resolution;

        if (!mediaSourceChanged) setUseCamera(pendingSettings.useCamera)
        if (!deviceChanged) setSelectedDevices(pendingSettings.selectedDevice);
        if (!extraSettingsChanged) setExtraSettings(pendingSettings.extraSettings);
        if (!maxBitratesChanged) setMaxBitrates(pendingSettings.maxBitrates);
        if (!maxFramerateChanged) setMaxFramerate(pendingSettings.maxFramerate);
        if (!resolutionChanged) setResolution(pendingSettings.resolution);

        if (triggerNewScreen || !mediaSourceChanged || !deviceChanged || !extraSettingsChanged ||
            !maxBitratesChanged || !maxFramerateChanged || !resolutionChanged) {
            setTriggerInit(true);
        }
        if (!mediaSourceChanged) setConstraints({ video: true, audio: true });

        setOpenSettings(!openSettings);
    }

    const unmountWebcaster = () => {
        if (videoElement && videoElement.srcObject) {
            let tracks = videoElement.srcObject.getTracks();
            tracks.forEach(track => track.stop());
            videoElement.srcObject = null;
            videoElement.pause();
            setVideoElement(null);
            if (client) dispose();
            console.log("Stopped Mediastream");
        }
    }

    useEffect(() => {
        if (!id) unmountWebcaster();
    }, [id])

    useEffect(() => {
        if (client && initializing) startBroadcast();
    }, [client])

    useEffect(() => {
        let videoTracks = finn?.current?.getVideoTracks();
        if (videoTracks) videoTracks[0].enabled = constraints.video;

        let audioTracks = finn?.current?.getAudioTracks();
        if (audioTracks) audioTracks[0].enabled = constraints.audio;

    }, [constraints])

    useEffect(() => {
        if (((selectedDevice.audio || selectedDevice.video) && !videoElement?.srcObj) && triggerInit) {
            initPreview();
            setTriggerInit(false);
            setTriggerNewScreen(false);
        }
    }, [selectedDevice, triggerInit]);

    useEffect(() => {
        getPermission();
    }, []);

    useEffect(() => {
        return () => {
            unmountWebcaster();
        }
    }, [videoElement]);

    return (
        <Grid container spacing={2}>
            <SnackbarMessage
                open={showError}
                close={handleCloseError}
                type={error.type}
            >
                <b>{error.code}</b> {error.message}
            </SnackbarMessage>
            {
                <ContentDialog
                    open={openSettings}
                    close={() => setOpenSettings(!openSettings)}
                    submit={handleSubmitChanges}
                    submitButton={"Apply changes"}
                    title="Settings"
                    underline="You can customize your webcaster settings here, including video settings, audio settings, and additional options."
                    content={
                        <WebcasterSettings
                            useCamera={useCamera}
                            selectedDevice={selectedDevice}
                            extraSettings={extraSettings}
                            maxBitrates={maxBitrates}
                            maxFramerate={maxFramerate}
                            resolution={resolution}
                            devices={devices}
                            openScreenshareDialog={() => { setTriggerNewScreen(true) }}
                            update={(e) => { setPendingSettings(e) }}
                        />
                    }
                />
            }
            {
                hasPermission === false &&
                <Grid item xs={12} mt={1}>
                    <SectionContainer
                        noMargin
                        caution
                        title="Permission denied"
                        underline="In order to use the nanoStream Webcaster, please grant access to camera and microphone."
                    >
                        <Typography variant="body1" sx={{ mt: 1 }}>
                            <b>To enable camera and microphone access</b>: <br />
                            - Click on the padlock icon in the address bar <br />
                            - Change camera and microphone permissions to 'Allow' or 'Ask (default)' <br />
                            - Refresh the page to apply the changes.
                        </Typography>
                    </SectionContainer>
                </Grid>
            }
            {
                hasPermission === true &&
                <Fragment>
                    <Grid item xs={12} md={6} xl={6}>
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{
                                position: 'absolute', m: 1, py: 0.5, px: 0.7,
                                bgcolor: alpha(theme.palette.common.black, 0.4),
                                color: theme.palette.common.white,
                                borderRadius: theme.spacing(0.5)
                            }}>
                                <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                                    nanoStream Webcaster Version {VERSION}
                                </Typography>
                                {
                                    status &&
                                    <Typography variant="subtitle2">
                                        Status: <b>{status}</b>
                                    </Typography>
                                }
                                {
                                    status && quality &&
                                    <Typography variant="subtitle2" >
                                        Quality: <b style={{ color: quality.color }}>{quality.title}</b>
                                    </Typography>
                                }
                            </Box>
                            <video
                                autoPlay muted playsInline
                                id={webcasterId}
                                ref={(video) => { setVideoElement(video) }}
                                style={{ width: '100%', maxWidth: '100%', borderRadius: theme.spacing(0.5) }}
                            ></video>
                            {
                                <Grid container
                                    justifyContent={'space-between'} alignItems={'center'}
                                    px={1.25} py={.75}
                                    sx={{
                                        borderRadius: theme.spacing(0.5),
                                        bgcolor: theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.grey[900],
                                    }}
                                >
                                    <Grid item>
                                        <Tooltip title={`${constraints.video ? "Mute" : "Unmute"} Video`}>
                                            <IconButton size="small" color="primary" onClick={handleQuickAction("video")}>
                                                {
                                                    constraints.video
                                                        ? <VideocamOutlined />
                                                        : <VideocamOffOutlined />
                                                }
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={`${constraints.audio ? "Mute" : "Unmute"} Audio`}>
                                            <IconButton size="small" color="primary" onClick={handleQuickAction("audio")}>
                                                {
                                                    constraints.audio
                                                        ? <MicNoneOutlined />
                                                        : <MicOffOutlined />
                                                }
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item>
                                        {
                                            initializing
                                                ? <Loading />
                                                :
                                                <Button
                                                    sx={{ mr: 1 }}
                                                    size="small"
                                                    variant="contained"
                                                    disabled={!enableStartStreaming}
                                                    onClick={initStreaming}
                                                >
                                                    {streaming ? "Stop" : "Start"} Streaming
                                                </Button>
                                        }
                                    </Grid>
                                    <Grid item>
                                        <Tooltip title="Settings">
                                            <IconButton disabled={streaming} size="small" color="primary" onClick={() => { setOpenSettings(!openSettings) }}>
                                                <SettingsOutlined />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Stream Playout">
                                            <IconButton size="small" color="primary" onClick={() => window.open(`${link.PLAYOUT}/${props.stream.id}`, '_target')}>
                                                <OndemandVideoOutlined />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>

                            }
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} xl={6}>
                        <Accordion defaultExpanded>
                            <AccordionSummary sx={{ flexDirection: 'row-reverse', alignItems: 'center' }}
                                expandIcon={<ArrowForwardIos sx={{ fontSize: '0.9rem', mx: 0.5 }} />}
                            >
                                Stats and Metrics
                            </AccordionSummary>
                            <AccordionDetails>
                                <SectionContainer
                                    contrast noMargin small
                                    title="Ingest Quality Indicators"
                                    underline="Detecting and addressing poor network conditions is crucial for ensuring a good end-to-end user experience. Specific statistics indicate the current upstream quality."
                                >
                                    <ContentTable
                                        data={[
                                            { label: "Packetloss", value: packetLoss !== null ? packetLoss : "-" },
                                            { label: "RTT", value: connectionRtt !== null ? connectionRtt : "-" }
                                        ]}
                                    />
                                </SectionContainer>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Fragment>
            }
        </Grid>
    )
}
