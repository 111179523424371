import React from 'react';
import clsx from 'clsx';

import moment from 'moment';

import * as link from '../../utils/helper/link-config';

import { makeStyles } from '@mui/styles';
import { Button, Chip, Divider, Grid, Typography } from '@mui/material';
import { HeadsetMicOutlined, HeadsetOutlined, NotInterested, OpenInNew, PieChartOutline, Poll, PollOutlined } from '@mui/icons-material';

import SectionHeader from '../global/SectionHeader';
import Options from './Options';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    headerRoot: {

    },
    row: {
        marginRight: theme.spacing(1),
    },
    chipLabel: {
        color: theme.palette.primary.main
    }
}));

export default function OrgaHeader(props) {
    const classes = useStyles();
    const orga = props.orga;

    const handleOpenSupport = () => {
        let supportUrl = `${link.NANO_SUPPORT}?bintu.id=${orga?.id}&bintu.name=${orga?.name}&bintu.webhook=${orga?.webhook}&bintu.vod=${orga?.vod}&bintu.webrtc=${orga?.webrtc}&bintu.transcoding=${orga?.transcoding}&bintu.h5live=${orga?.h5live}&bintu.hash=${orga?.hash}&bintu.hls=${orga?.hls}&bintu.secure=${orga?.secure}&bintu.metrics=${orga?.metrics}&bintu.sla=${orga?.sla}&bintu.trial=${orga?.trial}`
        window.open(supportUrl, "_blank");
    }

    return (
        <Grid container>
            <Grid item xs={12} className={classes.headerRoot} display="flex" alignItems="center">
                <Typography
                    variant="h3"
                    sx={{
                        lineHeight: 1
                    }}
                    mr={1}
                    color="primary"
                >
                    {orga.name}
                </Typography>

                <div className={classes.buttonWrapper}>
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        endIcon={<OpenInNew />}
                        onClick={handleOpenSupport}
                    >
                        Get Support
                    </Button>
                </div>
            </Grid>
            {/* <Grid item xs={12}>
                <Typography variant="body1" sx={{ fontWeight: 'inherit' }} color="textSecondary">
                    Streaming with <b>nanoStream Cloud</b> since <i>{moment(orga.created_at).utc().format('Do  MMMM YYYY')}</i>
                </Typography>
            </Grid> */}
            <Grid item xs={12} display="flex">
                <Options orga={orga} />
            </Grid>
        </Grid>

    )
}
